import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginPage from 'src/modules/Auth/pages/login';
import VerificationCodePage from 'src/modules/Auth/pages/VerificationCode';
import HolderSignupPage from 'src/modules/Auth/pages/holderSignup/HolderSignupPage';
import CreatePassword from 'src/modules/Auth/pages/CreatePassword';

const AuthRoutes = () => {
  return (
    <Routes>
      <Route index path="/login" Component={LoginPage} />;
      <Route path="/verification-code" Component={VerificationCodePage} />;
      <Route path="/holder-signup" Component={HolderSignupPage} />;
      <Route path="/create-password" Component={CreatePassword} />;
    </Routes>
  );
};

export default AuthRoutes;
