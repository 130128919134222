import React from 'react';
import MuiButton from '@mui/material/Button';
import MuiLink from '@mui/material/Link';
import * as appSx from 'src/shared/components/atomic/Button/button.style';
import type ButtonProps from './Button.types';

const Button = ({ children, variant, theme, sx, ...rest }: ButtonProps) => {
  const handleVariant = () => {
    if (variant === 'link') return 'text';
    return variant;
  };

  const handleSxProp = (style: object) => {
    if (variant !== 'text' && variant !== 'link')
      return [style, ...(Array.isArray(sx) ? sx : [sx])];

    return sx;
  };

  if (variant !== 'link')
    return (
      <MuiButton
        variant={handleVariant()}
        type="button"
        data-theme={theme}
        {...rest}
        sx={handleSxProp(appSx.button)}
      >
        {children}
      </MuiButton>
    );

  return (
    <MuiLink
      component="button"
      color={'inherit'}
      variant="body1"
      onClick={event => {
        rest?.onClick &&
          rest.onClick(
            event as React.MouseEvent<HTMLButtonElement, MouseEvent>
          );
      }}
      type="button"
      sx={sx}
    >
      {children}
    </MuiLink>
  );
};

export default Button;
