import React, { useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { AuthLayout } from 'src/modules/Auth/layout';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Form,
} from 'src/shared/components/atomic';
import { useSnackbarContext } from 'src/shared/components/atomic/Snackbar';

import { useGetAppToken, useVerifyEmail } from 'src/modules/Auth/api';
import { useAuthContext } from 'src/modules/Auth/contexts';
import { type Login } from 'src/modules/Auth/interfaces/requests';

import { useLogin } from 'src/modules/Auth/hooks';

import { useFullSpinnerLoadingContext } from 'src/shared/components/atomic/FullSpinnerLoading';

import * as sx from './LoginPage.styles';

const LoginPage = () => {
  const [isValid, setIsValid] = useState(true);
  const [needCompleteRegistration, setNeedCompleteRegistration] =
    useState(false);
  const [hasPassword, setHasPassword] = useState(false);

  const { updateAppToken, updateUserEmail } = useAuthContext();

  const { openSnackbar } = useSnackbarContext();

  const { open, hide } = useFullSpinnerLoadingContext();

  const { mutate: verifyEmail } = useVerifyEmail();

  const { data: appToken } = useGetAppToken();

  const { loginUser } = useLogin();

  const navigate = useNavigate();

  useEffect(() => {
    if (appToken) updateAppToken(appToken);
  }, [appToken, updateAppToken]);

  const handleLogin = async (login: Login) => {
    if (!hasPassword) {
      open();
      updateUserEmail(login.email);
      verifyEmail(login.email, {
        onSuccess: verifyEmailResult => {
          if (!verifyEmailResult) return;

          setIsValid(verifyEmailResult.isValid);

          if (!verifyEmailResult.isValid) return;

          setHasPassword(verifyEmailResult.hasPassword);
          setNeedCompleteRegistration(
            verifyEmailResult.needCompleteRegistration
          );

          if (!verifyEmailResult.hasPassword) navigate('../verification-code');
        },
        onError: () => {
          openSnackbar(
            'Não foi possível verificar o e-mail. Tente novamente.',
            { autoHideDuration: 3000, severity: 'error' }
          );
        },
        onSettled: () => {
          hide();
        },
      });
      return;
    }

    loginUser(login, !needCompleteRegistration);
  };

  const emailSchema = yup.object().shape({
    email: yup.string().email().required(),
  });

  const loginSchema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  });

  const handleValidationSchema = () =>
    hasPassword ? loginSchema : emailSchema;

  return (
    <Form
      submitHandler={handleLogin}
      validationSchema={handleValidationSchema()}
      noHtml5Validate
      getErrors={errors => {
        setIsValid(!errors);
      }}
    >
      <AuthLayout>
        <Grid item xs={12} sm={11} sx={sx.Container}>
          <TextField
            name={'email'}
            label={'E-mail'}
            type={'email'}
            showDefaultErrorMessage={false}
            sx={sx.EmailTextField}
          />
          {hasPassword ? (
            <TextField name={'password'} label={'Senha'} type={'password'} />
          ) : (
            !isValid && (
              <Box sx={sx.EmailInfo}>
                <InfoOutlinedIcon sx={sx.IconError} />
                <Typography sx={sx.InfoTextError}>
                  Por favor, insira um e-mail válido e tente novamente.
                </Typography>
              </Box>
            )
          )}
        </Grid>
      </AuthLayout>
    </Form>
  );
};

export default LoginPage;
