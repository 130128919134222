export const button = {
  '&.MuiButton-contained': {
    backgroundColor: '#F1F5F9',
    textTransform: 'capitalize',
    color: '#0F172A',
  },
};

export const menu = {
  mt: '24px',
  '& .MuiPaper-root': {
    width: '230px',
  },
};
