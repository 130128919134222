import React from 'react';
import MUITableContainer from '@mui/material/TableContainer';
import { type TableContainerProps } from 'src/shared/components/atomic/TableContainer/TableContainer.type';
import sx from 'src/shared/components/atomic/TableContainer/TableContainer.style';
import Paper from '../Paper';

const TableContainer = ({ children, ...props }: TableContainerProps) => {
  return (
    <MUITableContainer component={Paper} {...props} sx={sx.tableContainer}>
      {children}
    </MUITableContainer>
  );
};

export default TableContainer;
