import React from 'react';
import { TableHead as MUITableHead } from '@mui/material';
import { type TableHeadProps } from 'src/shared/components/atomic/TableHead/TableHead.type';
import sx from 'src/shared/components/atomic/TableHead/TableHead.style';

const TableHead = ({ children, ...props }: TableHeadProps) => {
  return (
    <MUITableHead {...props} sx={sx.tableHead}>
      {children}
    </MUITableHead>
  );
};

export default TableHead;
