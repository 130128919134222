export const Accordion = {
  width: '100%',
  border: '1px 0px solid #CBD5E1',
};

export const AccordionSummary = {
  borderBottom: '1px solid',
  borderColor: '#CBD5E1',
  pl: 0,
  '& .MuiAccordionSummary-content': {
    m: 0,
  },
};

export const AccordionHeaderContainer = {
  justifyContent: 'space-between',
  alignItems: 'center',
  p: '24px',
};

export const AccordionSumaryTitle = {
  fontWeight: 700,
  size: '20px',
  lineHeight: '20px',
};

export const AccordionSumaryGrayTittle = {
  color: '#94A3B8',
};

export const Alert = {
  border: '1px solid',
  p: '8px',
  '& .MuiAlert-message': {
    p: 0,
    overflow: 'hidden',
  },
};

export const AlertTitle = {
  fontWeight: 400,
  size: '16px',
  lineHeight: '16px',
  m: 0,
};

export const AlertBeneficiariesAvailable = {
  color: '#F97316',
};

export const AlertIrregularSignature = {
  color: '#DC2626',
};

export const AlertWhite = {
  color: '#334155',
  borderColor: '#334155',
  backgroundColor: '#FFFFFF',
};

export const Tooltip = {
  '& .MuiTooltip-tooltip': {
    p: '8px 24px',
    backgroundColor: '#F8FAFC',
  },
  '& .MuiTooltip-arrow': {
    color: '#F8FAFC',
  },
};
