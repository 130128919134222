import {
  signaturesHttpClient,
  beneficiariesHttpClient,
  peopleHttpClient,
} from 'src/core/services';
import { type IDependentTable } from 'src/shared/interfaces';
import { type IDependent } from 'src/shared/interfaces/dependent';

const useHttp = () => {
  const getDependents = async (holderId: string) => {
    return await signaturesHttpClient
      .get<IDependentTable>(`Holder/${holderId}`)
      .then(res => res.data);
  };

  const sendDependent = async (dependent: IDependent) => {
    const body = { ...dependent, BirthDate: dependent.dateOfBirth };
    return await beneficiariesHttpClient
      .post<IDependent>(`Dependent`, body)
      .then(res => res.data);
  };

  const updateDependent = async (
    dependent: IDependent,
    dependentId: string
  ) => {
    return await peopleHttpClient
      .put<IDependent>(`Dependent/${dependentId}`, dependent)
      .then(res => res.data);
  };

  const activateDependent = async (
    dependentId: string,
    signatureId: string
  ) => {
    return await beneficiariesHttpClient
      .patch<IDependentTable>(`${dependentId}/Activate`, {
        signatureId,
      })
      .then(res => res.data);
  };

  const inactivateDependent = async (
    dependentId: string,
    signatureId: number
  ) => {
    return await beneficiariesHttpClient
      .delete(`${dependentId}/Signature/${signatureId}`)
      .then(res => res.data);
  };

  return {
    getDependents,
    sendDependent,
    updateDependent,
    activateDependent,
    inactivateDependent,
  };
};

export { useHttp };
