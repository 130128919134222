import { signaturesHttpClient } from 'src/core/services';
import type Signature from 'src/modules/Signatures/types';

const useHttp = () => {
  const getAllSignatures = async (holderId: string) => {
    const holderId2 = '154BE1D4-136D-445F-BDA6-98C30FFC165B';

    return await signaturesHttpClient
      .get<Signature[]>(`Holder/${holderId}/Signatures`)
      .then(res => res.data);
  };

  return { getAllSignatures };
};

export default useHttp;
