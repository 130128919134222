import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ListPage } from 'src/modules/Dependent/pages/dependentList';

const DependentRoutes = () => {
  return (
    <Routes>
      <Route path="/" Component={ListPage} />;
    </Routes>
  );
};

export default DependentRoutes;
