import axios, { type AxiosError } from 'axios';

const baseAuthURL = process.env.REACT_APP_AUTH_URL as string;
const baseURL = process.env.REACT_APP_CONTRACT_API as string;
const accreditationURL = process.env.REACT_APP_ACCREDITATION_API as string;

export const authHttpClient = axios.create({
  baseURL: `${baseAuthURL}/`,
});

export const beneficiariesHttpClient = axios.create({
  baseURL: `${baseURL}/Beneficiaries/`,
});

export const peopleHttpClient = axios.create({
  baseURL: `${accreditationURL}/People/`,
});

export const signaturesHttpClient = axios.create({
  baseURL: `${baseURL}/Signatures/`,
});

[
  authHttpClient,
  beneficiariesHttpClient,
  peopleHttpClient,
  signaturesHttpClient,
].map(http =>
  http.interceptors.response.use(
    response => {
      return response;
    },
    async (error: AxiosError) => {
      console.error('Erro ao processar requisição =>', error);

      return await Promise.reject(error.request);
    }
  )
);

export const updateAuthToken = (token: string) => {
  peopleHttpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  beneficiariesHttpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  signaturesHttpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
};
