export const IrregularSignatureSx = {
  color: '#94A3B8',
};

export const DeleteDependentContainer = {
  gap: '32px',
  padding: '32px 24px',
  maxWidth: '400px',
  minWidth: '300px',
};

export const DeleteButton = {
  backgroundColor: '#DC2626',
};
