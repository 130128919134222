export const BaseLayout = {
  display: 'flex',
};

export const Container = {
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  pt: 5,
};

export const CenterContainer = {
  height: 'fit-content',
  p: 3,
  width: '95%',
  maxWidth: '500px',
};

export const PaperTitle = {
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '100%',
  color: '#0F172A',
};

export const PaperSubTitle = {
  pt: 2,
};

export const PasswordField = {
  mt: 1,
};

export const CriteriaAndIcon = {
  display: 'flex',
};

export const DefaultCriteria = {
  fontWeight: 400,
  fontSize: '14px',
  pl: 1,
};

export const CorrectCriteria = {
  ...DefaultCriteria,
  color: 'primary.main',
};
