const sx = {
  container: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    gap: '2px',
  },
  paperHeader: {
    backgroundColor: '#FFF',
    justifyContent: 'center',
    width: '80%',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
    padding: '16px 40px',
    direction: 'column',
  },
  paperBody: {
    backgroundColor: '#FFF',
    justifyContent: 'center',
    width: '80%',
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    padding: '24px 40px',
    direction: 'column',
    backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/little_woman.png')`,
    backgroundPosition: 'calc(100% - 40px) calc(100% - 24px)',
    backgroundRepeat: 'no-repeat',
    paddingBottom: '203px',
  },
  title: {
    fontSize: '20px',
    fontWeight: '500',
  },
  buttonWrapper: {
    alignSelf: 'flex-end',
    paddingTop: '40px',
  },
  button: {
    textTransform: 'uppercase',
    padding: '14px 30px',
  },
  link: {
    color: '#8FD16A',
    paddingInline: '4px',
  },
};

export default sx;
