import React, { useEffect, useState } from 'react';
import { Alert as MUIAlert, Snackbar as MUISnackbar } from '@mui/material';
import { type SnackbarProps } from 'src/shared/components/atomic/Snackbar/Snackbar.type';
import { useSnackbarContext } from 'src/shared/components/atomic/Snackbar/Snackbar.context';

const Snackbar = ({
  children,
  open,
  severity,
  autoHideDuration,
}: SnackbarProps) => {
  const { hideSnackbar } = useSnackbarContext();
  const [isOpen, setIsOpen] = useState<boolean>(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsOpen(false);
    hideSnackbar();
  };

  return (
    <>
      <MUISnackbar
        open={isOpen}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MUIAlert severity={severity} variant="filled">
          {children}
        </MUIAlert>
      </MUISnackbar>
    </>
  );
};

export default Snackbar;
