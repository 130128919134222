import React from 'react';
import type TooltipProps from 'src/shared/components/atomic/Tooltip/Tooltip.types';
import StyledTooltip from 'src/shared/components/atomic/Tooltip/Tooltip.styles';

const Tooltip = ({ children, ...rest }: TooltipProps) => {
  return (
    <StyledTooltip {...rest}>
      <span>{children}</span>
    </StyledTooltip>
  );
};

export default Tooltip;
