import { type AlertColor } from '@mui/material';
import React, {
  createContext,
  type PropsWithChildren,
  useContext,
  useState,
} from 'react';
import ReactDOM from 'react-dom';

type SnackbarState = {
  isOpen: boolean;
  message: string;
  options: SnackbarOptions;
  openSnackbar: (message: string, options?: SnackbarOptions) => void;
  hideSnackbar: () => void;
};

type SnackbarOptions = {
  severity?: AlertColor;
  autoHideDuration?: number | null;
};

const defaultValues = {
  isOpen: false,
  message: '',
  options: {
    severity: 'success',
    autoHideDuration: 5000,
  },
} as SnackbarState;

const SnackbarContext = createContext<SnackbarState>({
  ...defaultValues,
} as SnackbarState);

export const SnackbarContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultValues.isOpen);
  const [message, setMessage] = useState<string>(defaultValues.message);
  const [options, setOptions] = useState<SnackbarOptions>(
    defaultValues.options
  );

  const openSnackbar = (newMessage: string, newOptions?: SnackbarOptions) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setIsOpen(true);
      setMessage(newMessage);
      setOptions(Object.assign({}, defaultValues.options, newOptions));
    });
  };

  const hideSnackbar = () => {
    setIsOpen(false);
  };

  return (
    <SnackbarContext.Provider
      value={{
        isOpen,
        message,
        options,
        openSnackbar,
        hideSnackbar,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbarContext = () => useContext(SnackbarContext);
