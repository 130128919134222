import React, { useCallback } from 'react';

const usePageProtection = () => {
  const protectThisPage = useCallback(
    (
      currentPage: string,
      hasTempToken: boolean,
      hasLoginToken: boolean
    ): boolean => {
      if (currentPage === '/auth/login') return false;

      if (
        currentPage === '/auth/verification-code' ||
        currentPage === '/auth/create-password'
      )
        return !hasTempToken;

      return !hasLoginToken;
    },
    []
  );

  return { protectThisPage };
};

export default usePageProtection;
