import { useCallback } from 'react';

import { useGetUserByEmail } from 'src/modules/Auth/api';
import { useAuthContext } from 'src/modules/Auth/contexts';
import { useFullSpinnerLoadingContext } from 'src/shared/components/atomic/FullSpinnerLoading';
import type User from '../interfaces';

const useUpdateBasicUserInfo = () => {
  const { open, hide } = useFullSpinnerLoadingContext();

  const { updateUser } = useAuthContext();

  const { mutateAsync: getUserByEmail } = useGetUserByEmail();

  const updateBasicUserInfo = useCallback(
    async (email: string) => {
      open();

      let basicUserInfo: User = {
        signatureId: 0,
        dateOfBirth: '',
        documentNumber: '',
        email: '',
        gender: '',
        id: '',
        isHolder: true,
        name: '',
        personId: '',
        phoneNumber: '',
        status: 'Ativo',
      };

      await getUserByEmail(email, {
        onSuccess: response => {
          updateUser(response);
          basicUserInfo = response;
        },
        onSettled: () => {
          hide();
        },
      });
      return basicUserInfo;
    },
    [getUserByEmail, hide, open, updateUser]
  );

  return { updateBasicUserInfo };
};

export default useUpdateBasicUserInfo;
