import React, { useEffect, useState } from 'react';
import { Paper, Grid } from 'src/shared/components/atomic';
import BaseDependentLayout from 'src/shared/components/layout/BaseDependentLayout';
import { DependentForm } from 'src/shared/components/dependentForm';
import * as appSx from 'src/modules/Auth/pages/holderSignup/HolderSignupPage.style';
import { type IDependent } from '../../../../shared/interfaces/dependent';
import { useUpdateHolder } from 'src/modules/Auth/api';
import { useSnackbarContext } from 'src/shared/components/atomic/Snackbar';
import { useNavigate } from 'react-router';
import { useAuthContext } from 'src/modules/Auth';
import { type ErrorResponse } from 'src/modules/Auth/interfaces/responses';
import { useRemoveMask, useTransformDate } from 'src/core/helpers';

import { useUpdateBasicUserInfo } from 'src/modules/Auth/hooks';

const HolderSignupPage = () => {
  const { mutate: updateHolder } = useUpdateHolder();
  const { openSnackbar } = useSnackbarContext();
  const { user } = useAuthContext();
  const { updateBasicUserInfo } = useUpdateBasicUserInfo();

  const navigate = useNavigate();

  const handleSubmit = (dependent: IDependent) => {
    dependent.dateOfBirth = useTransformDate(dependent.dateOfBirth || '');
    dependent.phoneNumber = useRemoveMask(dependent.phoneNumber || '');
    dependent.documentNumber = useRemoveMask(dependent.documentNumber || '');
    dependent.id = user.id;
    updateHolder(dependent, {
      onSuccess: async () => {
        openSnackbar('Titular cadastrado com sucesso!');
        await updateBasicUserInfo(user.email);
        navigate('/signatures');
      },
      onError: e => {
        const error = e as XMLHttpRequest;
        const response = JSON.parse(error.responseText) as ErrorResponse;

        if (response.message) {
          openSnackbar(response.message, {
            autoHideDuration: 3000,
            severity: 'error',
          });
          return;
        }

        openSnackbar(
          'Um erro aconteceu ao tentar cadastrar o titular. Tente novamente.',
          {
            autoHideDuration: 3000,
            severity: 'error',
          }
        );
      },
    });
  };

  return (
    <BaseDependentLayout>
      <BaseDependentLayout.Header />
      <BaseDependentLayout.PageTitle title="Cadastro do Titular" />
      <BaseDependentLayout.Body>
        <Grid container sx={appSx.container}>
          <Paper>
            <DependentForm
              holder={user}
              onSubmit={handleSubmit}
              hideCancelButton
            />
          </Paper>
        </Grid>
      </BaseDependentLayout.Body>
    </BaseDependentLayout>
  );
};

export default HolderSignupPage;
