import React, { useEffect } from 'react';

import { Grid } from 'src/shared/components/atomic';

import type SignaturesListItemProps from './SignaturesListItem.types';
import * as sx from './SignaturesListItem.styles';

import { SignaturesListItemBody, SignaturesListItemHeader } from './components';
import {
  SignaturesListItemContextProvider,
  useSignaturesListItemContext,
} from './contexts';

const SignaturesListItemComponent = ({
  signature,
}: SignaturesListItemProps) => {
  const { setCurrentSignature, isChangingDependentsManually } =
    useSignaturesListItemContext();

  useEffect(() => {
    if (!isChangingDependentsManually) setCurrentSignature(signature);
  }, [isChangingDependentsManually, setCurrentSignature, signature]);

  return (
    <Grid container sx={sx.Container}>
      <SignaturesListItemHeader />
      <SignaturesListItemBody />
    </Grid>
  );
};

const SignaturesListItem = ({ signature }: SignaturesListItemProps) => {
  return (
    <SignaturesListItemContextProvider>
      <SignaturesListItemComponent signature={signature} />
    </SignaturesListItemContextProvider>
  );
};

export default SignaturesListItem;
