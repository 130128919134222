import React, { useState } from 'react';
import { TextField as MUITextField } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { type PasswordIconProps } from 'src/shared/components/atomic/TextField/TextField.type';
import type TextFieldProps from 'src/shared/components/atomic/TextField/TextField.type';

const TextField = ({
  label,
  name,
  value,
  showDefaultErrorMessage = true,
  type,
  disabled,
  mask,
  onChange: appOnChange,
  ...props
}: TextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const { control } = useFormContext();

  const isPasswordField = type === 'password';

  const handleType = (): typeof type => {
    if (!isPasswordField) return type;

    return showPassword ? 'text' : 'password';
  };

  const onChangeMasked = (fieldValue: any): string => {
    if (mask) {
      fieldValue = fieldValue.replace(/\D/g, '');
      fieldValue = fieldValue.slice(0, mask.match(/#/g)?.length);
      fieldValue = fieldValue.split('');

      let fieldMask: string[] = [];

      fieldMask = mask.split('');

      let i = 0;
      const maskedValue = fieldValue.map((value: string, index: number) => {
        if (fieldMask[index + i] === '#') return value;
        const str = fieldMask[index + i] + value;
        i++;
        return str;
      });
      return maskedValue.join('');
    }
    return fieldValue;
  };

  return (
    <Controller
      name={name}
      defaultValue={onChangeMasked(value || '')}
      control={control}
      render={({
        field: { onChange: rhfOnChange, ref, name, value },
        fieldState,
      }) => {
        return (
          <MUITextField
            label={label}
            size="small"
            variant="standard"
            fullWidth
            error={!!fieldState.error}
            helperText={showDefaultErrorMessage && fieldState.error?.message}
            disabled={disabled}
            inputRef={ref}
            onChange={e => {
              let {
                target: { value },
              } = e;
              value = onChangeMasked(value);
              appOnChange?.(e);
              rhfOnChange(value);
            }}
            name={name}
            value={value}
            {...props}
            InputProps={{
              endAdornment: (
                <PasswordIcon
                  showIcon={isPasswordField}
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                />
              ),
            }}
            type={handleType()}
          />
        );
      }}
    />
  );
};

const PasswordIcon = ({
  showIcon,
  showPassword,
  setShowPassword,
}: PasswordIconProps) => {
  if (!showIcon) return <></>;

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <InputAdornment position="start">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => {
          setShowPassword(prev => !prev);
        }}
        onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton>
    </InputAdornment>
  );
};

export default TextField;
