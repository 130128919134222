export const button = {
  '&[data-theme="warn"]': {
    color: '#DC2626',
    borderColor: '#DC2626',
  },
  '&.MuiButton-contained': {
    color: '#FFFFFF',
    textTransform: 'capitalize',
  },
  '&.MuiButton-outlined': {
    borderRadius: '12px',
    border: '2px solid',
  },
  '&[disabled]': {
    color: '#94A3B8',
    MouseEvent: 'none',
    cursor: 'default',
    backgroundColor: '#E2E8F0 ',
  },
};
