export const Container = {
  minWidth: '15rem',
  width: '30vw',
  maxWidth: '30rem',
  justifyContent: 'center',
};

export const ImagesContainer = {
  flexDirection: 'row',
  alignItems: 'flex-end',
  justifyContent: 'space-around',
};

export const Footer = {
  pt: '3rem !important',
};
