import React from 'react';
import { TableCell as MUITableCell } from '@mui/material';
import { type TableCellProps } from 'src/shared/components/atomic/TableCell/TableCell.type';
import appSx from 'src/shared/components/atomic/TableCell/TableCell.style';

const TableCell = ({ children, sx, ...props }: TableCellProps) => {
  const handleSxProp = (style: object) => [
    style,
    ...(Array.isArray(sx) ? sx : [sx]),
  ];

  return (
    <MUITableCell {...props} sx={handleSxProp(appSx.tableCell)}>
      {children}
    </MUITableCell>
  );
};

export default TableCell;
