import React from 'react';
import MuiSelect from '@mui/material/Select';
import type SelectProps from 'src/shared/components/atomic/Select/Select.types';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, InputLabel } from '@mui/material';

const Select = ({ label, name, value, children, ...props }: SelectProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      defaultValue={value || ''}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <FormControl>
            <InputLabel sx={{ ml: '-12px' }} id="labelId">
              {label}
            </InputLabel>
            <MuiSelect
              labelId="labelId"
              label={label}
              variant="standard"
              error={!!fieldState.error}
              inputRef={field.ref}
              {...field}
              {...props}
            >
              {children}
            </MuiSelect>
          </FormControl>
        );
      }}
    />
  );
};

export default Select;
