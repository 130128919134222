import { useQuery } from 'react-query';
import useHttp from 'src/modules/Signatures/api/http';
import type Signature from 'src/modules/Signatures/types';

export const useGetAllSignatures = (holderId: string) => {
  const { getAllSignatures } = useHttp();

  return useQuery<Signature[]>(
    ['getAllSignatures', holderId],
    async () => await getAllSignatures(holderId)
  );
};
