import styled from '@emotion/styled';

export const BackgroundContainer = styled.div`
  background-image: url('${process.env.PUBLIC_URL}/imgs/auth_background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 40%;
  opacity: 0.8;
  height: 100vh;
  width: 100vw;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
