export const container = {
  justifyContent: 'center',
};

export const paperHeader = {
  backgroundColor: '#FFF',
  justifyContent: 'center',
  width: '100%',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  padding: '24px',
  direction: 'column',
};

export const paperBody = {
  backgroundColor: '#FFF',
  justifyContent: 'center',
  width: '100%',
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
  padding: '24px',
  direction: 'column',
  backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/little_woman.png')`,
  backgroundPosition: 'calc(100% - 24px) calc(100% - 24px)',
  backgroundRepeat: 'no-repeat',
  paddingBottom: '203px',
};

export const title = {
  fontSize: '20px',
  fontWeight: '700',
  mb: 2,
};

export const buttonWrapper = {
  alignSelf: 'flex-end',
  paddingTop: '40px',
};

export const button = {
  textTransform: 'uppercase',
  padding: '14px 30px',
};

export const link = {
  color: '#8FD16A',
  paddingInline: '4px',
};
