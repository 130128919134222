import { createTheme } from '@mui/material';

const Theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#8FD16A',
    },
    text: { primary: '#707070' },
  },
  typography: {
    fontFamily: [
      'Maven Pro',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    allVariants: {
      color: '#707070',
    },
  },
});

export default Theme;
