import React, { useEffect } from 'react';

import { useAuthContext } from 'src/modules/Auth';

import { useGetAllSignatures } from 'src/modules/Signatures/api';

import { useFullSpinnerLoadingContext } from 'src/shared/components/atomic/FullSpinnerLoading';

import { useSignaturesContext } from 'src/modules/Signatures/contexts';

import { SignaturesListItem } from './components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Grid,
  Tooltip,
  Typography,
} from 'src/shared/components/atomic';

import * as sx from './SignaturesList.styles';
import type Signature from 'src/modules/Signatures/types';

const SignaturesList = () => {
  const { user } = useAuthContext();
  const { open, hide } = useFullSpinnerLoadingContext();
  const { setSignatures, signatures, refetch } = useSignaturesContext();

  const {
    data: signaturesResponse,
    isLoading,
    refetch: forceGetAllSignatures,
  } = useGetAllSignatures(user.id);

  useEffect(() => {
    open();
    if (!isLoading) {
      setSignatures(signaturesResponse);
      hide();
    }
  }, [hide, isLoading, open, setSignatures, signaturesResponse]);

  useEffect(() => {
    if (!refetch) return;

    open();
    forceGetAllSignatures()
      .then(response => {
        setSignatures(response.data);
      })
      .finally(() => {
        hide();
      });
  }, [forceGetAllSignatures, hide, open, refetch, setSignatures]);

  const tittleSx = (signature: Signature) => {
    if (signature.status === 'Active') return {};

    return sx.AccordionSumaryGrayTittle;
  };

  if (signatures.length === 0) return <></>;

  return (
    <Grid item container>
      {signatures.map(signature => (
        <Accordion
          key={signature.id}
          sx={sx.Accordion}
          disableGutters
          elevation={3}
        >
          <AccordionSummary id={`${signature.id}`} sx={sx.AccordionSummary}>
            <Grid container item sx={sx.AccordionHeaderContainer}>
              <Typography sx={[sx.AccordionSumaryTitle, tittleSx(signature)]}>
                {signature.description}
              </Typography>
              <Message signature={signature} />
            </Grid>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <SignaturesListItem signature={signature} />
          </AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  );
};

type MessageProps = {
  signature: Signature;
};

const Message = ({ signature }: MessageProps) => {
  if (
    signature.status === 'Active' &&
    signature.availableBeneficiaries &&
    signature.beneficiaries.length < signature.totalBeneficiariesAvailable - 1
  )
    return (
      <Alert
        sx={[sx.Alert, sx.AlertIrregularSignature]}
        severity="error"
        color="warning"
        icon={false}
      >
        <AlertTitle sx={[sx.AlertTitle, sx.AlertIrregularSignature]}>
          {signature.beneficiaries.length}/
          {signature.totalBeneficiariesAvailable - 1} dependentes cadastrados
        </AlertTitle>
      </Alert>
    );

  if (signature.irregularSignature)
    return (
      <Tooltip
        placement="left"
        sx={sx.Tooltip}
        title={
          <Typography textAlign={'center'}>
            Seu plano está irregular porque a quantidade máxima de dependentes
            foi reduzida. Para continuar a utilizar estes serviços, você precisa
            escolher os dependentes que você deseja remover do seu plano atual.
            Por favor, escolha os dependentes para editá-los e clique em
            &quot;Deletar&quot; para continuar.
          </Typography>
        }
      >
        <Alert
          sx={[sx.Alert, sx.AlertIrregularSignature]}
          severity="warning"
          color="error"
          icon={false}
        >
          <AlertTitle sx={[sx.AlertTitle, sx.AlertIrregularSignature]}>
            Plano irregular
          </AlertTitle>
        </Alert>
      </Tooltip>
    );

  if (signature.status === 'Suspended')
    return (
      <Tooltip
        placement="left"
        sx={sx.Tooltip}
        title={
          <Typography textAlign={'center'}>
            Seu plano está suspenso por alguma irregularidade. Para continuar
            com seu plano atual, você precisa regularizar seu contrato junto à
            Oi, entrando em contato pelo número 0800 031 8000.
          </Typography>
        }
      >
        <Alert sx={[sx.Alert, sx.AlertWhite]} icon={false}>
          <AlertTitle sx={[sx.AlertTitle, sx.AlertWhite]}>
            Plano suspenso
          </AlertTitle>
        </Alert>
      </Tooltip>
    );

  if (signature.status === 'Canceled')
    return (
      <Tooltip
        placement="left"
        sx={sx.Tooltip}
        title={
          <Typography textAlign={'center'}>
            Este plano foi cancelado, caso queira continuar utilizando nossos
            serviços, entre em contato pelo número 0800 031 8000.
          </Typography>
        }
      >
        <Alert sx={[sx.Alert, sx.AlertWhite]} icon={false}>
          <AlertTitle sx={[sx.AlertTitle, sx.AlertWhite]}>
            Plano cancelado
          </AlertTitle>
        </Alert>
      </Tooltip>
    );

  return <></>;
};

export default SignaturesList;
