import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useSnackbarContext } from 'src/shared/components/atomic/Snackbar';
import { useLogin as useLoginApi } from 'src/modules/Auth/api';
import { useAuthContext } from 'src/modules/Auth/contexts';
import { useFullSpinnerLoadingContext } from 'src/shared/components/atomic/FullSpinnerLoading';

import { useUpdateBasicUserInfo } from 'src/modules/Auth/hooks';

import { type Login } from '../interfaces';

const useLogin = () => {
  const { openSnackbar } = useSnackbarContext();

  const { open, hide } = useFullSpinnerLoadingContext();

  const { mutate: useLogin } = useLoginApi();

  const { updateLoginToken } = useAuthContext();

  const { updateBasicUserInfo } = useUpdateBasicUserInfo();

  const navigate = useNavigate();

  const loginUser = (login: Login, isSignupComplete: boolean) => {
    open();

    useLogin(
      { email: login.email, password: login.password },
      {
        onSuccess: async result => {
          await updateBasicUserInfo(login.email);

          updateLoginToken(result);

          isSignupComplete
            ? navigate('/signatures')
            : navigate('/auth/holder-signup');
        },
        onError: e => {
          const error = e as XMLHttpRequest;
          if (error.status === 400)
            openSnackbar(
              'E-mail ou senha inválido(s). Por favor, tente novamente.',
              { severity: 'error', autoHideDuration: 3000 }
            );
        },
        onSettled: () => {
          hide();
        },
      }
    );
  };

  return { loginUser };
};

export default useLogin;
