import React from 'react';
import MuiTypography from '@mui/material/Typography';

import type TypographyProps from './Typography.types';

const Typography = ({ children, ...rest }: TypographyProps) => {
  return <MuiTypography {...rest}>{children}</MuiTypography>;
};

export default Typography;
