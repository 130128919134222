import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { useAuthContext } from 'src/modules/Auth/contexts';

const PageProtection = () => {
  const { shouldForceLogout, logout } = useAuthContext();

  if (shouldForceLogout()) {
    logout(true);
    return <Navigate to={'auth/login'} replace />;
  }

  return <Outlet />;
};

export default PageProtection;
