import React, { useRef, useEffect, useState } from 'react';

import {
  Button,
  Grid,
  Typography,
  TextField,
} from 'src/shared/components/atomic';

import { useFullSpinnerLoadingContext } from 'src/shared/components/atomic/FullSpinnerLoading';
import { useSnackbarContext } from 'src/shared/components/atomic/Snackbar';

import { useAuthContext } from 'src/modules/Auth';

import { useReSendVerificationCode } from 'src/modules/Auth/api';

import type VerificationCodePageFormProps from 'src/modules/Auth/pages/VerificationCode/components/VerificationCodePageForm/VerificationCodePage.Form.types';
import * as sx from 'src/modules/Auth/pages/VerificationCode/components/VerificationCodePageForm/VerificationCodePage.Form.styles';

const VerificationCodePageForm = ({
  isAllFilled,
}: VerificationCodePageFormProps) => {
  const [hasValueInput1, setHasValueInput1] = useState(false);
  const [hasValueInput2, setHasValueInput2] = useState(false);
  const [hasValueInput3, setHasValueInput3] = useState(false);
  const [hasValueInput4, setHasValueInput4] = useState(false);

  const input1 = useRef<HTMLInputElement>();
  const input2 = useRef<HTMLInputElement>();
  const input3 = useRef<HTMLInputElement>();
  const input4 = useRef<HTMLInputElement>();

  const { user } = useAuthContext();
  const userEmail = user.email;

  const { mutate: reSendVerificationCode } = useReSendVerificationCode();

  const { open, hide } = useFullSpinnerLoadingContext();

  const { openSnackbar } = useSnackbarContext();

  useEffect(() => {
    isAllFilled(
      hasValueInput1 && hasValueInput2 && hasValueInput3 && hasValueInput4
    );
  }, [
    isAllFilled,
    hasValueInput1,
    hasValueInput2,
    hasValueInput3,
    hasValueInput4,
  ]);

  return (
    <Grid container item sx={sx.Container}>
      <Grid container item xs={12} justifyContent={'space-between'}>
        <TextField
          sx={sx.SquareField}
          variant="outlined"
          name="value1"
          showDefaultErrorMessage={false}
          inputProps={{
            maxLength: 1,
          }}
          onKeyUp={({ key, target }) => {
            const field = target as HTMLInputElement;
            setHasValueInput1(!!field.value);
            if (key !== 'Backspace' && field.value) input2.current?.focus();
          }}
          inputRef={e => (input1.current = e)}
          autoFocus
        />
        <TextField
          sx={sx.SquareField}
          variant="outlined"
          name="value2"
          showDefaultErrorMessage={false}
          inputProps={{
            maxLength: 1,
          }}
          onKeyUp={({ key, target }) => {
            const field = target as HTMLInputElement;
            setHasValueInput2(!!field.value);
            if (key !== 'Backspace') {
              if (field.value) input3.current?.focus();
            } else input1.current?.focus();
          }}
          inputRef={e => (input2.current = e)}
        />
        <TextField
          sx={sx.SquareField}
          variant="outlined"
          name="value3"
          showDefaultErrorMessage={false}
          inputProps={{
            maxLength: 1,
          }}
          onKeyUp={({ key, target }) => {
            const field = target as HTMLInputElement;
            setHasValueInput3(!!field.value);
            if (key !== 'Backspace') {
              if (field.value) input4.current?.focus();
            } else input2.current?.focus();
          }}
          inputRef={e => (input3.current = e)}
        />
        <TextField
          sx={sx.SquareField}
          variant="outlined"
          name="value4"
          showDefaultErrorMessage={false}
          inputProps={{
            maxLength: 1,
          }}
          onKeyUp={({ key, target }) => {
            const field = target as HTMLInputElement;
            setHasValueInput4(!!field.value);
            if (key === 'Backspace') input3.current?.focus();
          }}
          inputRef={e => (input4.current = e)}
        />
      </Grid>
      <Grid item sx={sx.CaptionText}>
        <Typography>Não recebeu o código?</Typography>
        <Button
          variant="link"
          onClick={() => {
            open();
            reSendVerificationCode(userEmail, {
              onSuccess: () => {
                openSnackbar('Código reenviado com sucesso!');
              },
              onError: e => {
                console.error(
                  'Algum problema ocorreu ao reenviar o código =>',
                  e
                );
                openSnackbar('Algum problema ocorreu ao reenviar o código!', {
                  severity: 'error',
                  autoHideDuration: 3000,
                });
              },
              onSettled: () => {
                hide();
              },
            });
          }}
          sx={sx.LinkButton}
        >
          Clique para reenviar
        </Button>
      </Grid>
    </Grid>
  );
};

export default VerificationCodePageForm;
