import React, { useEffect, useState } from 'react';
import BaseDependentLayout from 'src/shared/components/layout/BaseDependentLayout';
import { DependentList } from 'src/modules/Dependent/pages/dependentList/components/dependentsList';
import { Button, Grid, Typography } from 'src/shared/components/atomic';
import sx from 'src/modules/Dependent/pages/dependentList/DependentListPage.style';
import { DependentForm } from 'src/shared/components/dependentForm';
import { useModalContext } from 'src/shared/components/atomic/Modal';
import { type IDependent } from 'src/shared/interfaces/dependent';
import { useSnackbarContext } from 'src/shared/components/atomic/Snackbar';
import { type ErrorResponse } from 'src/modules/Auth/interfaces';
import { useRemoveMask, useTransformDate } from 'src/core/helpers';
import { useAuthContext } from 'src/modules/Auth';
import { IDependentTable } from '../../../../shared/interfaces/dependent-table';
import {
  useSendDependent,
  useGetDependents,
} from 'src/modules/Dependent/api/api';

const ListPage = () => {
  const { setModalContent } = useModalContext();

  const { mutate: postDependent } = useSendDependent();
  const { mutate: getDependents } = useGetDependents();

  const { openSnackbar } = useSnackbarContext();

  const { user } = useAuthContext();

  const [tableData, setTableData] = useState({} as IDependentTable);

  useEffect(() => {
    if (user.id !== '') getTableData();
  }, [user.id]);

  const getTableData = () => {
    getDependents(user.id, {
      onSuccess: data => {
        setTableData(data);
      },
      onError: e => {
        const error = e as XMLHttpRequest;

        openSnackbar(error.responseText, {
          autoHideDuration: 3000,
          severity: 'error',
        });
      },
    });
  };

  const handleSubmit = (dependent: IDependent) => {
    dependent.signatureId = tableData?.signatureId;
    dependent.dateOfBirth = useTransformDate(dependent.dateOfBirth || '');
    dependent.phoneNumber = useRemoveMask(dependent.phoneNumber || '');
    dependent.documentNumber = useRemoveMask(dependent.documentNumber || '');
    postDependent(dependent, {
      onSuccess: () => {
        openSnackbar('Dependente cadastrado com sucesso!');
        setModalContent(null);
        getTableData();
      },
      onError: e => {
        const error = e as XMLHttpRequest;
        const response = JSON.parse(error.responseText) as ErrorResponse;

        openSnackbar(response.message, {
          autoHideDuration: 3000,
          severity: 'error',
        });
      },
    });
  };

  const handleClick = () => {
    setModalContent({
      title: 'Cadastrar Dependente',
      component: (
        <DependentForm
          isDependent={true}
          onSubmit={handleSubmit}
          beneficiariesLength={tableData?.beneficiaries?.length}
          totalBeneficiariesAvailable={tableData?.totalBeneficiariesAvailable}
        />
      ),
    });
  };

  return (
    <BaseDependentLayout>
      <BaseDependentLayout.Header />
      <BaseDependentLayout.Body>
        <Grid sx={sx.container} container>
          <Grid sx={sx.paperHeader} direction="column" item container>
            <Typography sx={sx.title}>Plano Oi Fibra</Typography>
          </Grid>
          <Grid sx={sx.paperBody} direction="column" item container>
            <Grid item>
              <Typography>
                Aqui você pode gerenciar a sua assinatura, incluindo, editando
                ou atualizando os seus dados e de dependentes.
              </Typography>
            </Grid>
            <Grid item sx={sx.buttonWrapper}>
              <Button
                sx={sx.button}
                onClick={handleClick}
                disabled={
                  tableData?.totalBeneficiariesAvailable ===
                  tableData?.beneficiaries?.length
                }
              >
                Cadastrar Dependente
              </Button>
            </Grid>
            <Grid item>
              <DependentList data={tableData} />
            </Grid>
            <Grid item container>
              <Grid item sx={{ paddingTop: '150px' }}>
                <Typography sx={{ maxWidth: '851px' }}>
                  Agora você já pode realizar agendamentos para os serviços de
                  saúde contratados através da nossa
                  <a
                    style={sx.link}
                    href="https://prod-oifibra-landing.azurewebsites.net/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    página oficial
                  </a>
                  ,
                  <a
                    style={sx.link}
                    href="https://play.google.com/store/apps/dev?id=8161234029328702769&hl=pt_BR&gl=US"
                    target="_blank"
                    rel="noreferrer"
                  >
                    App do Beneficiário
                  </a>
                  ou ainda pela Central de Atendimento, ligando no 3003-6291.
                </Typography>
                <Typography sx={{ paddingTop: '10px' }}>
                  Bom atendimento 💚
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BaseDependentLayout.Body>
    </BaseDependentLayout>
  );
};

export default ListPage;
