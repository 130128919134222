import { ModeEditOutlineOutlined } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useTransformDate, useRemoveMask } from 'src/core/helpers';
import { useAuthContext } from 'src/modules/Auth';
import { useGetUserByEmail, useUpdateBeneficiary } from 'src/modules/Auth/api';
import { type ErrorResponse } from 'src/modules/Auth/interfaces/responses';
import {
  type ITableData,
  type IDependentList,
} from 'src/modules/Dependent/pages/dependentList/components/dependentsList/dependent-list.type';
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from 'src/shared/components/atomic';
import { useModalContext } from 'src/shared/components/atomic/Modal';
import { useSnackbarContext } from 'src/shared/components/atomic/Snackbar';
import { DependentForm } from 'src/shared/components/dependentForm';
import { type IDependentTable, type IBeneficiary } from 'src/shared/interfaces';
import { type IDependent } from 'src/shared/interfaces/dependent';

const TableData: React.FC<IDependentList> = ({ data }) => {
  const { setModalContent } = useModalContext();
  const { mutateAsync: getUserByEmail } = useGetUserByEmail();
  const { mutate: updateBeneficiary } = useUpdateBeneficiary();
  const { openSnackbar } = useSnackbarContext();
  const { user } = useAuthContext();

  if (!data)
    return (
      <TableRow>
        <TableCell align="center" colSpan={6}>
          Não foi encontrado nenhum resultado de busca.
        </TableCell>
      </TableRow>
    );

  const formatPhone = (phone: string) => {
    const phoneNumbers = phone.replace(/\D/g, '').trim();
    return `(${phoneNumbers.slice(0, 2)}) ${phoneNumbers.slice(
      2,
      7
    )} ${phoneNumbers.slice(7, 11)}`;
  };

  const handleEditDependent = (email: string) => {
    getUserByEmail(email, {
      onSuccess: result => {
        setModalContent({
          title: 'Editar Dependente',
          component: (
            <DependentForm onSubmit={handleSubmit} dependentData={result} />
          ),
        });
      },
      onError: e => {
        const error = e as XMLHttpRequest;
        const response = JSON.parse(error.responseText) as ErrorResponse;

        openSnackbar(response.message, {
          autoHideDuration: 3000,
          severity: 'error',
        });
      },
    });
  };

  const handleSubmit = (dependent: IDependent) => {
    dependent.dateOfBirth = useTransformDate(dependent.dateOfBirth || '');
    dependent.phoneNumber = useRemoveMask(dependent.phoneNumber || '');
    dependent.documentNumber = useRemoveMask(dependent.documentNumber || '');
    dependent.id = user?.id;
    updateBeneficiary(dependent, {
      onSuccess: () => {
        openSnackbar('Dependente atualizado com sucesso!');
        setModalContent(null);
      },
      onError: e => {
        const error = e as XMLHttpRequest;
        const response = JSON.parse(error.responseText) as ErrorResponse;

        openSnackbar(response.message, {
          autoHideDuration: 3000,
          severity: 'error',
        });
      },
    });
  };

  return (
    <>
      {data?.map((row: IBeneficiary) => (
        <TableRow key={row.id}>
          <TableCell>{row.name}</TableCell>
          <TableCell>{row.status}</TableCell>
          <TableCell>{row.email}</TableCell>
          <TableCell>{formatPhone(row.phoneNumber ?? '')}</TableCell>
          <TableCell>{row.isHolder ? 'Titular' : 'Dependente'}</TableCell>
          <TableCell>
            <ModeEditOutlineOutlined
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                handleEditDependent(row.email || '');
              }}
            />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

const DependentList: React.FC<ITableData> = ({ data }: ITableData) => {
  const [tableData, setTableData] = useState({} as IDependentTable);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Situação</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>Telefone</TableCell>
              <TableCell>Permissão</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableData data={tableData?.beneficiaries} />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default DependentList;
