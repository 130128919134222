/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';

import MuiMenu from '@mui/material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Button } from 'src/shared/components/atomic';

import type MenuProps from './Menu.types';
import * as sx from './Menu.styles';

const Menu = ({ children, buttonText }: MenuProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="contained"
        sx={sx.button}
        endIcon={<ExpandMoreIcon />}
      >
        {buttonText}
      </Button>
      <MuiMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={sx.menu}
      >
        {children}
      </MuiMenu>
    </div>
  );
};

export default Menu;
