import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SignaturesPage from 'src/modules/Signatures/pages/signatures';
import { SignaturesContextProvider } from 'src/modules/Signatures/contexts';

const SignaturesRoutes = () => {
  return (
    <SignaturesContextProvider>
      <Routes>
        <Route index path="/" Component={SignaturesPage} />;
      </Routes>
    </SignaturesContextProvider>
  );
};

export default SignaturesRoutes;
