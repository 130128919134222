import CloseIcon from '@mui/icons-material/Close';
import React, {
  createContext,
  type ReactElement,
  type ReactNode,
  useContext,
  useState,
} from 'react';
import {
  Box,
  Grid,
  Modal,
  Paper,
  Typography,
} from 'src/shared/components/atomic';

type ModalState = {
  title: string;
  component: ReactNode;
};

type ModalContextValue = {
  modalContent: ModalState | null;
  setModalContent: (content: ModalState | null) => void;
};

export const ModalContext = createContext<ModalContextValue>({
  modalContent: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setModalContent: () => {},
});

type ModalProviderProps = {
  children: React.ReactNode;
};

export function ModalContextProvider(props: ModalProviderProps) {
  const { children } = props;
  const [modalContent, setModalContent] = useState<ModalState | null>(null);
  const handleClose = () => {
    setModalContent(null);
  };

  return (
    <ModalContext.Provider value={{ modalContent, setModalContent }}>
      {children}
      <Modal
        open={!!modalContent}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Grid
          container
          sx={{
            justifyContent: 'center',
            height: '100vh',
            alignContent: 'center',
          }}
        >
          <Paper
            sx={{
              borderRadius: '12px',
              overflow: 'auto',
              maxHeight: 'calc(100vh - 2rem)',
              maxWidth: 'calc(100vw - 2rem)',
            }}
          >
            <Grid container sx={{ paddingInline: '24px', pt: '24px' }}>
              <Grid>
                <Typography
                  sx={{ color: '#0F172A', fontSize: '28px', fontWeight: '600' }}
                >
                  {modalContent?.title}
                </Typography>
              </Grid>
              <Grid
                sx={{ flexGrow: 1, textAlign: 'right', alignSelf: 'center' }}
              >
                <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
              </Grid>
            </Grid>
            {modalContent?.component ? (
              <Box sx={{ maxWidth: 'fit-content', maxHeight: 'fit-content' }}>
                {modalContent?.component as ReactElement}
              </Box>
            ) : (
              <></>
            )}
          </Paper>
        </Grid>
      </Modal>
    </ModalContext.Provider>
  );
}

export const useModalContext = () => useContext(ModalContext);
