import React from 'react';
import MuiPaper from '@mui/material/Paper';

import type PaperProps from './Paper.types';

const Paper = ({ children, ...rest }: PaperProps) => {
  return <MuiPaper {...rest}>{children}</MuiPaper>;
};

export default Paper;
