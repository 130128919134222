const sx = {
  tableHead: {
    '& th': {
      paddingBottom: '8px',
      fontWeight: '700',
      color: '#707070',
      borderBottom: '1px solid #707070',
    },
  },
};

export default sx;
