import React, { type PropsWithChildren } from 'react';
import {
  Box,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from 'src/shared/components/atomic';
import * as sx from './BaseDependentLayout.styles';
import type BaseDependentLayoutProps from './BaseDependentLayout.types';
import {
  type LayoutPageTitleProps,
  type LayoutHeaderProps,
} from './BaseDependentLayout.types';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import { useAuthContext } from 'src/modules/Auth';

const LayoutHeader = ({
  children,
  showMenu,
  buttonText,
  perfilOnClick,
}: LayoutHeaderProps) => {
  const { logout } = useAuthContext();

  return (
    <Grid sx={sx.layoutHeader}>
      {showMenu && (
        <Grid sx={sx.menuContainer}>
          <Menu buttonText={buttonText ?? ''}>
            <MenuItem
              onClick={() => {
                if (perfilOnClick) perfilOnClick();
              }}
            >
              <AccountCircleOutlinedIcon fontSize="small" sx={{ mr: '8px' }} />
              <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>
                Perfil
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                logout();
              }}
            >
              <LogoutOutlinedIcon fontSize="small" sx={{ mr: '8px' }} />{' '}
              <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>
                Sair
              </Typography>
            </MenuItem>
          </Menu>
        </Grid>
      )}
      {children}
    </Grid>
  );
};

const LayoutBody = (props: PropsWithChildren) => {
  return <Grid sx={sx.layoutBody}>{props.children}</Grid>;
};

const BaseDependentLayout = ({
  sx: sxProp,
  children,
}: BaseDependentLayoutProps) => {
  return (
    <Grid sx={[sx.baseLayout, ...(Array.isArray(sxProp) ? sxProp : [sxProp])]}>
      {children}
    </Grid>
  );
};

const LayoutPageTitle = ({ title, children }: LayoutPageTitleProps) => {
  return (
    <Grid sx={sx.layoutPageTitle}>
      <Typography variant="h1" sx={sx.pageTitleText}>
        {title}
      </Typography>
      {children}
    </Grid>
  );
};

BaseDependentLayout.Header = LayoutHeader;
BaseDependentLayout.PageTitle = LayoutPageTitle;
BaseDependentLayout.Body = LayoutBody;

export default BaseDependentLayout;
