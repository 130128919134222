import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { type FormProps } from 'src/shared/components/atomic/Form/Form.type';

function Form<T>({
  submitHandler,
  validationSchema,
  noHtml5Validate,
  getErrors,
  ...props
}: FormProps<T>) {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { errors } = methods.formState;

  useEffect(() => {
    if (!getErrors) return;

    if (JSON.stringify(errors) !== '{}') getErrors(errors);
    else getErrors(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <FormProvider {...methods}>
      <form
        noValidate={noHtml5Validate}
        onSubmit={methods.handleSubmit(submitHandler)}
      >
        {props.children}
      </form>
    </FormProvider>
  );
}

export default Form;
