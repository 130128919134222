import React from 'react';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import {
  Alert,
  IconButton,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
} from 'src/shared/components/atomic';

import { useInactivateDependent } from 'src/modules/Dependent/api/api';
import { useUpdateBeneficiary } from 'src/modules/Auth/api';
import { useFullSpinnerLoadingContext } from 'src/shared/components/atomic/FullSpinnerLoading';
import { useModalContext } from 'src/shared/components/atomic/Modal';
import { useSnackbarContext } from 'src/shared/components/atomic/Snackbar';
import { useTransformDate, useRemoveMask } from 'src/core/helpers';
import { type ErrorResponse } from 'src/modules/Auth/interfaces/responses';

import { useSignaturesListItemContext } from 'src/modules/Signatures/pages/signatures/components/SignaturesList/components/SignaturesListItem/contexts';

import * as sx from './SignaturesListItemBody.styles';
import { DependentForm } from 'src/shared/components/dependentForm';
import { type IDependent } from 'src/shared/interfaces/dependent';

const SignaturesListItemBody = () => {
  const {
    signature,
    irregularSignature,
    removeDependent,
    getDependent,
    totalBeneficiariesAvailable,
    editDependent,
    shouldBlockEntireLine,
  } = useSignaturesListItemContext();
  const { setModalContent } = useModalContext();
  const { openSnackbar } = useSnackbarContext();
  const { open, hide } = useFullSpinnerLoadingContext();
  const { mutate: inactivateDependent } = useInactivateDependent();
  const { mutate: updateBeneficiary } = useUpdateBeneficiary();

  const formatPhone = (phone: string) => {
    const phoneNumbers = phone.replace(/\D/g, '').trim();
    return `(${phoneNumbers.slice(0, 2)}) ${phoneNumbers.slice(
      2,
      7
    )} ${phoneNumbers.slice(7, 11)}`;
  };

  const irregularSignatureSx = () => {
    if (
      !shouldBlockEntireLine &&
      (!irregularSignature ||
        signature.beneficiaries.length <= totalBeneficiariesAvailable)
    )
      return {};

    return sx.IrregularSignatureSx;
  };

  const handleClick = (dependentId: string) => {
    if (
      irregularSignature &&
      signature.beneficiaries.length > totalBeneficiariesAvailable
    ) {
      setModalContent({
        title: 'Deletar dependente',
        component: (
          <DeleteDependent
            dependentId={dependentId}
            handleDeleteClick={handleDeleteClick}
          />
        ),
      });
      return;
    }

    const dependent = getDependent(dependentId) as Required<IDependent>;

    setModalContent({
      title: 'Editar dependente',
      component: (
        <DependentForm
          onSubmit={handleEditSubmit}
          dependentData={dependent}
          isDependent
          isEdit
        />
      ),
    });
  };

  const handleDeleteClick = (dependentId: string) => {
    open();
    inactivateDependent(
      { dependentId, signatureId: signature.id },
      {
        onSuccess: () => {
          openSnackbar('Dependente inativado com sucesso!');
          removeDependent(dependentId);
          setModalContent(null);
        },
        onError: e => {
          const error = e as XMLHttpRequest;

          openSnackbar(error.responseText, {
            autoHideDuration: 3000,
            severity: 'error',
          });
        },
        onSettled: () => {
          hide();
        },
      }
    );
  };

  const handleEditSubmit = (dependent: IDependent) => {
    open();
    dependent.dateOfBirth = useTransformDate(dependent.dateOfBirth ?? '');
    dependent.phoneNumber = useRemoveMask(dependent.phoneNumber ?? '');
    dependent.documentNumber = useRemoveMask(dependent.documentNumber ?? '');

    updateBeneficiary(dependent, {
      onSuccess: () => {
        openSnackbar('Dependente atualizado com sucesso!');
        setModalContent(null);
        editDependent(dependent);
      },
      onError: e => {
        const error = e as XMLHttpRequest;
        const response = JSON.parse(error.responseText) as ErrorResponse;

        if (response.message) {
          openSnackbar(response.message, {
            autoHideDuration: 3000,
            severity: 'error',
          });
          return;
        }

        openSnackbar(
          'Um erro aconteceu ao tentar atualizar este dependente. Tente novamente.',
          {
            autoHideDuration: 3000,
            severity: 'error',
          }
        );
      },
      onSettled: () => {
        hide();
      },
    });
  };

  return (
    <Grid item>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>Telefone</TableCell>
              <TableCell>Permissão</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {signature.beneficiaries?.map(beneficiarie => (
              <TableRow key={beneficiarie.id}>
                <TableCell sx={irregularSignatureSx()}>
                  {beneficiarie.name}
                </TableCell>
                <TableCell sx={irregularSignatureSx()}>
                  {beneficiarie.email}
                </TableCell>
                <TableCell sx={irregularSignatureSx()}>
                  {formatPhone(beneficiarie.phoneNumber ?? '')}
                </TableCell>
                <TableCell sx={irregularSignatureSx()}>Dependente</TableCell>
                <TableCell>
                  {shouldBlockEntireLine ? (
                    <RemoveCircleOutlineIcon sx={{ color: '#94A3B8' }} />
                  ) : (
                    <IconButton
                      onClick={() => {
                        handleClick(beneficiarie.id ?? '');
                      }}
                    >
                      {!irregularSignature ||
                      signature.beneficiaries.length <=
                        totalBeneficiariesAvailable ? (
                        <EditOutlinedIcon />
                      ) : (
                        <RemoveCircleOutlineIcon sx={{ color: '#DC2626' }} />
                      )}
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

type DeleteDependentProps = {
  dependentId: string;
  handleDeleteClick: (dependentId: string) => void;
};

const DeleteDependent = ({
  dependentId,
  handleDeleteClick,
}: DeleteDependentProps) => {
  const { setModalContent } = useModalContext();

  return (
    <Grid container sx={sx.DeleteDependentContainer}>
      <Grid item>
        <Typography align="center">
          Você tem certeza que deseja deletar este dependente? Para cadastrá-lo
          novamente, você precisará entrar em contato com o SAC.
        </Typography>
      </Grid>
      <Grid
        item
        container
        sx={{ justifyContent: 'space-between', gap: '16px' }}
      >
        <Grid item flex={1}>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => {
              setModalContent(null);
            }}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item flex={1}>
          <Button
            variant="contained"
            fullWidth
            color="error"
            onClick={() => {
              handleDeleteClick(dependentId);
            }}
          >
            Deletar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SignaturesListItemBody;
