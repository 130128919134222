import React, {
  type PropsWithChildren,
  createContext,
  useState,
  useContext,
  useCallback,
} from 'react';

import type SignaturesContextProps from './SignaturesContext.types';
import type Signature from '../types';
import { type IDependent } from 'src/shared/interfaces/dependent';

const SignaturesContext = createContext<SignaturesContextProps>(
  {} as SignaturesContextProps
);

const SignaturesContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [signatures, setContextSignatures] = useState<Signature[]>([]);
  const [refetch, setRefetch] = useState(false);

  const addNewDependentToSignature = (
    signatureId: number,
    dependent: IDependent
  ) => {
    setContextSignatures(prev =>
      prev.map(signature =>
        signature.id === signatureId
          ? {
              ...signature,
              beneficiaries: [...signature.beneficiaries, dependent],
            }
          : signature
      )
    );

    setRefetch(true);
  };

  const removeDependentFromSignature = (
    signatureId: number,
    dependentId: string
  ) => {
    setContextSignatures(prev =>
      prev.map(signature =>
        signature.id === signatureId
          ? {
              ...signature,
              beneficiaries: signature.beneficiaries.filter(
                beneficiarie => beneficiarie.id !== dependentId
              ),
            }
          : signature
      )
    );

    setRefetch(true);
  };

  const setSignatures = useCallback((signatures?: Signature[]) => {
    setContextSignatures([]);
    if (signatures) setContextSignatures(signatures);
    setRefetch(false);
  }, []);

  return (
    <SignaturesContext.Provider
      value={{
        signatures,
        refetch,
        setSignatures,
        addNewDependentToSignature,
        removeDependentFromSignature,
      }}
    >
      {children}
    </SignaturesContext.Provider>
  );
};

const useSignaturesContext = () => {
  const context = useContext(SignaturesContext);
  if (!context) {
    throw new Error(
      'useSignaturesContext deve ser utilizado dentro do SignaturesContextProvider'
    );
  }
  return context;
};

export { SignaturesContextProvider, useSignaturesContext };
