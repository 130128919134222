import React, { useCallback } from 'react';

import type Key from 'src/core/storages/localStorage/localStorage.types';

const useLocalStorage = () => {
  const setLocalStorageItem = useCallback(<T>(key: Key, value: T) => {
    localStorage.setItem(key, JSON.stringify(value));
  }, []);

  const getLocalStorageItem = useCallback(<T>(key: Key) => {
    const result = localStorage.getItem(key);
    if (!result) return undefined;

    return JSON.parse(result) as T;
  }, []);

  const removeLocalStorageItem = useCallback((key: Key) => {
    localStorage.removeItem(key);
  }, []);

  const clearLocalStorage = useCallback(() => {
    localStorage.clear();
  }, []);

  return {
    setLocalStorageItem,
    getLocalStorageItem,
    removeLocalStorageItem,
    clearLocalStorage,
  };
};

export default useLocalStorage;
