import React from 'react';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import type AccordionSummaryProps from 'src/shared/components/atomic/AccordionSummary/AccordionSummary.types';

const AccordionSummary = ({ children, id, ...rest }: AccordionSummaryProps) => {
  return (
    <MuiAccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={`${id ?? ''}-content`}
      id={`${id ?? ''}-header`}
      {...rest}
    >
      {children}
    </MuiAccordionSummary>
  );
};

export default AccordionSummary;
