import React from 'react';

import { BaseAuthLayout } from 'src/shared/components/layout';
import { Button, Grid } from 'src/shared/components/atomic';

import { type SendButtonProps } from './Auth.layout.types';
import type AuthLayoutProps from './Auth.layout.types';
import * as sx from 'src/modules/Auth/layout/AuthLayout/Auth.layout.styles';

const AuthLayout = ({
  children,
  isSendButtonDisabled,
  iscancelButtonDisabled,
  showCancelButton,
  sendOnClick,
  cancelOnClick,
}: AuthLayoutProps) => {
  return (
    <BaseAuthLayout>
      <Grid container rowSpacing={2} sx={sx.Container}>
        <Grid container item sx={sx.ImagesContainer}>
          <img
            src={`${process.env.PUBLIC_URL}/imgs/logo_oisaude.png`}
            alt="Logo da Oi Saúde"
            width={'55%'}
          />
          <img
            src={`${process.env.PUBLIC_URL}/imgs/logo_docway.png`}
            alt="Logo da DocWay"
            width={'35%'}
            style={{ paddingBottom: '3px' }}
          />
        </Grid>
        {children}
        {showCancelButton ? (
          <Grid container item columnSpacing={3} sx={sx.Footer}>
            <Grid item xs={6}>
              <Button
                disabled={iscancelButtonDisabled}
                variant="outlined"
                fullWidth
                onClick={cancelOnClick}
                color={'error'}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6}>
              <SendButton
                isSendButtonDisabled={isSendButtonDisabled}
                sendOnClick={sendOnClick}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} sm={11} sx={sx.Footer}>
            <SendButton
              isSendButtonDisabled={isSendButtonDisabled}
              sendOnClick={sendOnClick}
            />
          </Grid>
        )}
      </Grid>
    </BaseAuthLayout>
  );
};

const SendButton = ({ isSendButtonDisabled, sendOnClick }: SendButtonProps) => {
  return (
    <Button
      disabled={isSendButtonDisabled}
      variant="outlined"
      fullWidth
      onClick={sendOnClick}
      type={'submit'}
    >
      Entrar
    </Button>
  );
};

export default AuthLayout;
