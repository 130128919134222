import React from 'react';
import MuiGrid from '@mui/material/Grid';

import type GridProps from './Grid.types';

const Grid = ({ children, ...rest }: GridProps) => {
  return <MuiGrid {...rest}>{children}</MuiGrid>;
};

export default Grid;
