export const Container = {
  pt: '3rem !important',
};

export const EmailTextField = {
  mb: 5,
};

export const EmailInfo = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '35px',
};

export const Icon = {
  fontSize: 'inherit',
};

export const IconError = {
  transform: 'rotate(180deg)',
  color: 'red',
  ...Icon,
};

export const InfoText = {
  width: '85%',
  ml: 2,
  fontSize: '13px',
};

export const InfoTextError = {
  color: 'red',
  ...InfoText,
};
