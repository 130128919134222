import React from 'react';

import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  Tooltip,
  Typography,
} from 'src/shared/components/atomic';
import {
  useNumberToWords,
  useRemoveMask,
  useTransformDate,
} from 'src/core/helpers';
import { useModalContext } from 'src/shared/components/atomic/Modal';
import { useSnackbarContext } from 'src/shared/components/atomic/Snackbar';

import { useSignaturesListItemContext } from 'src/modules/Signatures/pages/signatures/components/SignaturesList/components/SignaturesListItem/contexts';

import * as sx from './SignaturesListItemHeader.styles';
import { DependentForm } from 'src/shared/components/dependentForm';
import { type IDependent } from 'src/shared/interfaces/dependent';
import { useSendDependent } from 'src/modules/Dependent/api/api';
import { type ErrorResponse } from 'src/modules/Auth/interfaces';
import { useFullSpinnerLoadingContext } from 'src/shared/components/atomic/FullSpinnerLoading';

const SignaturesListItemHeader = () => {
  return (
    <Grid container item sx={sx.Container}>
      <Grid item>
        <AlertHeader />
      </Grid>
      <Grid item sx={sx.ButtonContainer}>
        <ButtonContainer />
      </Grid>
    </Grid>
  );
};

const AlertHeader = () => {
  const {
    totalBeneficiariesAvailable,
    irregularSignature,
    totalBeneficiariesAvailableInWords,
    shouldShowAlert,
    signature,
  } = useSignaturesListItemContext();

  const { numberToWords } = useNumberToWords();

  if (!shouldShowAlert) return <></>;

  if (
    irregularSignature &&
    signature.beneficiaries.length > totalBeneficiariesAvailable
  )
    return (
      <Alert
        sx={[sx.Alert, sx.AlertIrregularSignature]}
        severity="warning"
        color="error"
      >
        <AlertTitle sx={[sx.AlertTitle, sx.AlertIrregularSignature]}>
          Número máximo de dependentes excedido.
        </AlertTitle>
        Temos uma atualização importante sobre sua assinatura: a quantidade
        máxima de dependentes foi reduzida para {totalBeneficiariesAvailable} (
        {numberToWords(totalBeneficiariesAvailable, false)}). Para continuar com
        sua assinatura atual, você precisa <strong>deletar</strong> os
        dependentes, que você não deseja manter, manualmente. Por favor, escolha
        o dependente para editá-lo e clique em &quot;
        <strong>Deletar</strong>&quot; para continuar.
      </Alert>
    );

  if (signature.status === 'Suspended')
    return (
      <Alert
        sx={[sx.Alert, sx.AlertIrregularSignature]}
        severity="warning"
        color="error"
      >
        <AlertTitle sx={[sx.AlertTitle, sx.AlertIrregularSignature]}>
          Plano suspenso.
        </AlertTitle>
        Seu plano está suspenso por alguma irregularidade. Para continuar com
        seu plano atual, você precisa regularizar seu contrato junto à Oi,
        entrando em contato pelo número <strong>0800 031 8000</strong>.
      </Alert>
    );

  if (signature.status === 'Canceled')
    return (
      <Alert
        sx={[sx.Alert, sx.AlertIrregularSignature]}
        severity="warning"
        color="error"
      >
        <AlertTitle sx={[sx.AlertTitle, sx.AlertIrregularSignature]}>
          Plano cancelado.
        </AlertTitle>
        Este plano foi cancelado, caso queira continuar utilizando nossos
        serviços, entre em contato pelo número <strong>0800 031 8000</strong>.
      </Alert>
    );

  return (
    <Alert
      sx={[sx.Alert, sx.AlertBeneficiariesAvailable]}
      severity="error"
      color="warning"
    >
      <AlertTitle sx={[sx.AlertTitle, sx.AlertBeneficiariesAvailable]}>
        Vagas disponíveis
      </AlertTitle>
      Vagas disponíveis! Você possui vagas disponíveis para adicionar mais
      dependentes! Atualmente, você tem {totalBeneficiariesAvailableInWords} em
      sua assinatura. Adicione mais dependentes para aproveitar ao máximo a sua
      assinatura.
    </Alert>
  );
};

const ButtonContainer = () => {
  const {
    signature,
    shouldAddMoreBeneficiaries,
    addNewDependent,
    shouldShowButtonToolTip,
    totalBeneficiariesAvailable,
  } = useSignaturesListItemContext();
  const { setModalContent } = useModalContext();

  const { mutate: postDependent } = useSendDependent();

  const { openSnackbar } = useSnackbarContext();

  const { open, hide } = useFullSpinnerLoadingContext();

  const handleSubmit = (dependent: IDependent) => {
    open();
    dependent.signatureId = signature.id;
    dependent.dateOfBirth = useTransformDate(dependent.dateOfBirth ?? '');
    dependent.phoneNumber = useRemoveMask(dependent.phoneNumber ?? '');
    dependent.documentNumber = useRemoveMask(dependent.documentNumber ?? '');
    postDependent(dependent, {
      onSuccess: () => {
        openSnackbar('Dependente cadastrado com sucesso!');
        setModalContent(null);
        addNewDependent(dependent);
      },
      onError: e => {
        const error = e as XMLHttpRequest;
        const response = JSON.parse(error.responseText) as ErrorResponse;

        if (response.message) {
          openSnackbar(response.message, {
            autoHideDuration: 3000,
            severity: 'error',
          });
          return;
        }

        openSnackbar(
          'Um erro aconteceu ao tentar cadastrar este dependente. Tente novamente.',
          {
            autoHideDuration: 3000,
            severity: 'error',
          }
        );
      },
      onSettled: () => {
        hide();
      },
    });
  };

  const handleClick = () => {
    setModalContent({
      title: 'Cadastrar dependente',
      component: (
        <DependentForm
          isDependent={true}
          onSubmit={handleSubmit}
          beneficiariesLength={signature.beneficiaries.length}
          totalBeneficiariesAvailable={totalBeneficiariesAvailable}
        />
      ),
    });
  };

  return (
    <>
      {shouldShowButtonToolTip ? (
        <Tooltip title={<TooltipMessage />}>
          <Button variant="contained" disabled>
            Cadastrar dependente
          </Button>
        </Tooltip>
      ) : (
        <Button
          onClick={handleClick}
          variant="contained"
          disabled={!shouldAddMoreBeneficiaries}
        >
          Cadastrar dependente
        </Button>
      )}
    </>
  );
};

const TooltipMessage = () => {
  return (
    <Grid sx={sx.TooltipContainer}>
      <Typography sx={sx.TooltipTitle}>
        Limite de dependentes alcançado
      </Typography>
      <Typography sx={sx.TooltipText}>
        Seu limite de dependentes cadastrados foi alcançado com sucesso!
        Lamentamos informar que você não pode adicionar mais dependentes no
        momento. Se você precisar cadastrar um novo dependente, considere entrar
        em contato e realizar a compra de mais dependentes.
      </Typography>
    </Grid>
  );
};

export default SignaturesListItemHeader;
