import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

import {
  Button,
  Form,
  Grid,
  Paper,
  TextField,
  Typography,
} from 'src/shared/components/atomic';

import { useAuthContext } from 'src/modules/Auth';

import { useCreatePassword } from 'src/modules/Auth/api';

import { useFullSpinnerLoadingContext } from 'src/shared/components/atomic/FullSpinnerLoading';
import { useSnackbarContext } from 'src/shared/components/atomic/Snackbar';

import BaseDependentLayout from 'src/shared/components/layout/BaseDependentLayout';

import * as sx from 'src/modules/Auth/pages/CreatePassword/CreatePasswordPage.styles';
import { type CriteriaProps } from './CreatePasswordPage.types';
import { useNavigate } from 'react-router';
import { type ErrorResponse } from '../../interfaces';
import { useLogin } from 'src/modules/Auth/hooks';

const CreatePasswordPage = () => {
  const [criteria1, setCriteria1] = useState(false);
  const [criteria2, setCriteria2] = useState(false);
  const [criteria3, setCriteria3] = useState(false);
  const [criteria4, setCriteria4] = useState(false);
  const [criteria5, setCriteria5] = useState(false);

  const { user } = useAuthContext();

  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [repeatPasswordHasErrors, setRepeatPasswordHasErrors] = useState(false);
  const [userData, setUserData] = useState(user);

  const { open, hide } = useFullSpinnerLoadingContext();

  const { openSnackbar } = useSnackbarContext();

  const { mutate: createpassword } = useCreatePassword();

  const navigate = useNavigate();

  const { loginUser } = useLogin();

  const shouldSubmit =
    criteria1 &&
    criteria2 &&
    criteria3 &&
    criteria4 &&
    criteria5 &&
    !repeatPasswordHasErrors &&
    !!repeatPassword;

  useEffect(() => {
    setCriteria1(password.length >= 8);
    setCriteria2(/[A-Z]/.test(password));
    setCriteria3(/[a-z]/.test(password));
    setCriteria4(/[0-9]/.test(password));
    setCriteria5(/[*@!#%&()^~{}\-+/|\\=]+/.test(password));
  }, [password]);

  useEffect(() => {
    if (!repeatPassword) {
      setRepeatPasswordHasErrors(false);
      return;
    }
    setRepeatPasswordHasErrors(password !== repeatPassword);
  }, [password, repeatPassword]);

  useEffect(() => {
    setUserData(user);
  }, [user]);

  const handleSubmit = async () => {
    if (!shouldSubmit) return;

    open();
    createpassword(
      { email: user.email, password },
      {
        onSuccess: () => {
          openSnackbar('Senha cadastrada com sucesso!');
          loginUser({ email: user.email, password }, false);
        },
        onError: e => {
          const error = e as XMLHttpRequest;
          const response = JSON.parse(error.responseText) as ErrorResponse;

          openSnackbar(response.message, {
            autoHideDuration: 3000,
            severity: 'error',
          });
        },
        onSettled: () => {
          hide();
        },
      }
    );
  };

  const schema = yup.object().shape({});

  return (
    <BaseDependentLayout sx={sx.BaseLayout}>
      <BaseDependentLayout.Header />
      <BaseDependentLayout.PageTitle title="Criar senha" />
      <BaseDependentLayout.Body>
        <Grid container sx={sx.Container}>
          <Paper elevation={3} sx={sx.CenterContainer}>
            <Form submitHandler={handleSubmit} validationSchema={schema}>
              <Grid container item spacing={3}>
                <Grid item xs={12}>
                  <Typography sx={sx.PaperTitle}>
                    Bem vindo, {user.name}!
                  </Typography>
                  <Typography sx={sx.PaperSubTitle}>
                    Agora, crie uma senha forte.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    label={'E-mail'}
                    value={userData.email}
                    disabled
                  />
                  <TextField
                    sx={sx.PasswordField}
                    name="password"
                    type="password"
                    label={'Senha'}
                    onChange={e => {
                      setPassword(e.target.value);
                    }}
                  />
                  <TextField
                    name="repeatPassword"
                    type="password"
                    label={'Repetir senha'}
                    onChange={e => {
                      setRepeatPassword(e.target.value);
                    }}
                    error={repeatPasswordHasErrors}
                    helperText={
                      repeatPasswordHasErrors
                        ? 'A senha digitada é diferente da anterior'
                        : ''
                    }
                  />
                </Grid>
                <Grid item spacing={1} container xs={12}>
                  <Criteria text="Mínimo 8 caracteres;" success={criteria1} />
                  <Criteria
                    text="Um caractere alfabético maiúsculo;"
                    success={criteria2}
                  />
                  <Criteria
                    text="Um caractere alfabético minúsculo;"
                    success={criteria3}
                  />
                  <Criteria text="Um caractere numérico;" success={criteria4} />
                  <Criteria text="Um caractere especial." success={criteria5} />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    disabled={!shouldSubmit}
                    variant="outlined"
                    type="submit"
                    fullWidth
                  >
                    Cadastrar Senha
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Paper>
        </Grid>
      </BaseDependentLayout.Body>
    </BaseDependentLayout>
  );
};

const Criteria = ({ text, success }: CriteriaProps) => {
  return (
    <Grid item xs={12} sx={sx.CriteriaAndIcon}>
      {success ? (
        <CheckCircleOutlineOutlinedIcon color="primary" />
      ) : (
        <DoDisturbOnOutlinedIcon />
      )}

      <Typography sx={success ? sx.CorrectCriteria : sx.DefaultCriteria}>
        {text}
      </Typography>
    </Grid>
  );
};

export default CreatePasswordPage;
