export const Container = {
  flexDirection: 'column',
};

export const Alert = {
  mb: 2,
};

export const AlertTitle = {
  fontWeight: 700,
};

export const AlertBeneficiariesAvailable = {
  color: '#F97316',
};

export const AlertIrregularSignature = {
  color: '#DC2626',
};

export const ButtonContainer = {
  display: 'flex',
  flexDirection: 'row-reverse',
  mb: 2,
};

export const TooltipContainer = {
  p: '16px 24px',
};

export const TooltipTitle = {
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '24px',
};

export const TooltipText = {
  paddingTop: '16px',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '21px',
};
