import React, {
  type PropsWithChildren,
  createContext,
  useState,
  useContext,
  useCallback,
} from 'react';

import { useNumberToWords } from 'src/core/helpers';

import type SignaturesListItemContextProps from './SignaturesListItemContext.types';
import type Signature from 'src/modules/Signatures/types';
import { type IDependent } from 'src/shared/interfaces/dependent';

import { useSignaturesContext } from 'src/modules/Signatures/contexts';

const SignaturesListItemContext = createContext<SignaturesListItemContextProps>(
  {} as SignaturesListItemContextProps
);

const SignaturesListItemContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [signature, setSignature] = useState<Signature>({} as Signature);
  const [
    totalBeneficiariesAvailableInWords,
    setTotalBeneficiariesAvailableInWords,
  ] = useState('');
  const [shouldAddMoreBeneficiaries, setShouldAddMoreBeneficiaries] =
    useState(true);
  const [irregularSignature, setIrregularSignature] = useState(false);
  const [totalBeneficiariesAvailable, setTotalBeneficiariesAvailable] =
    useState(0);
  const [shouldShowAlert, setShouldShowAlert] = useState(true);
  const [shouldShowButtonToolTip, setShouldShowButtonToolTip] = useState(false);
  const [isChangingDependentsManually, setIsChangingDependentsManually] =
    useState(false);
  const [shouldBlockEntireLine, setShouldBlockEntireLine] = useState(false);

  const { addNewDependentToSignature, removeDependentFromSignature } =
    useSignaturesContext();

  const HOLDER_QUANTITY = 1;

  const { numberToWords } = useNumberToWords();

  const setCurrentSignature = useCallback(
    (signature: Signature, isChangingDependentsManually?: boolean) => {
      setIsChangingDependentsManually(!!isChangingDependentsManually);
      setSignature(signature);

      const realTotalBeneficiariesAvailabe =
        signature.totalBeneficiariesAvailable - HOLDER_QUANTITY;

      setTotalBeneficiariesAvailable(realTotalBeneficiariesAvailabe);

      const totalBeneficiariesAvailable =
        realTotalBeneficiariesAvailabe - signature.beneficiaries.length;

      const totalBeneficiaries = numberToWords(totalBeneficiariesAvailable);

      const plural = totalBeneficiariesAvailable === 1 ? '' : 's';

      setTotalBeneficiariesAvailableInWords(
        `${totalBeneficiariesAvailable} (${totalBeneficiaries}) vaga${plural} não preenchida${plural}`
      );

      if (signature.irregularSignature) {
        setShouldAddMoreBeneficiaries(false);
        setShouldShowButtonToolTip(false);
        setIrregularSignature(true);
        setShouldBlockEntireLine(false);
        return;
      }

      if (signature.status === 'Canceled' || signature.status === 'Suspended') {
        setShouldAddMoreBeneficiaries(false);
        setShouldShowButtonToolTip(false);
        setIrregularSignature(false);
        setShouldBlockEntireLine(true);
        return;
      }

      const shouldAddMoreBeneficiaries = isChangingDependentsManually
        ? totalBeneficiariesAvailable > 0
        : signature.availableBeneficiaries;

      setShouldAddMoreBeneficiaries(shouldAddMoreBeneficiaries);

      setShouldShowAlert(shouldAddMoreBeneficiaries);

      setShouldShowButtonToolTip(!shouldAddMoreBeneficiaries);
    },
    [numberToWords]
  );

  const addNewDependent = (dependent: IDependent) => {
    addNewDependentToSignature(signature.id, dependent);
  };

  const removeDependent = (dependentId: string) => {
    removeDependentFromSignature(signature.id, dependentId);
  };

  const getDependent = (dependentId: string) => {
    const beneficiarie = signature.beneficiaries.find(
      beneficiarie => beneficiarie.id === dependentId
    );

    return beneficiarie as IDependent;
  };

  const editDependent = (dependent: IDependent) => {
    const newDependents = signature.beneficiaries.map(beneficiarie =>
      beneficiarie.id !== dependent.id ? beneficiarie : dependent
    );
    setSignature({ ...signature, beneficiaries: newDependents });
  };

  return (
    <SignaturesListItemContext.Provider
      value={{
        signature,
        totalBeneficiariesAvailableInWords,
        shouldAddMoreBeneficiaries,
        irregularSignature,
        totalBeneficiariesAvailable,
        shouldShowAlert,
        shouldShowButtonToolTip,
        isChangingDependentsManually,
        shouldBlockEntireLine,
        setCurrentSignature,
        addNewDependent,
        removeDependent,
        getDependent,
        editDependent,
      }}
    >
      {children}
    </SignaturesListItemContext.Provider>
  );
};

const useSignaturesListItemContext = () => {
  const context = useContext(SignaturesListItemContext);
  if (!context) {
    throw new Error(
      'useSignaturesListItemContext deve ser utilizado dentro do SignaturesListItemContextProvider'
    );
  }
  return context;
};

export { SignaturesListItemContextProvider, useSignaturesListItemContext };
