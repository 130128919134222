import * as yup from 'yup';

const VerificationCodePageFormSchema = yup.object().shape({
  value1: yup.string().required(),
  value2: yup.string().required(),
  value3: yup.string().required(),
  value4: yup.string().required(),
});

export default VerificationCodePageFormSchema;
