// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import React, {
  createContext,
  PropsWithChildren,
  useState,
  useContext,
  useCallback,
} from 'react';

import { type FullSpinnerLoadingContextProps } from 'src/shared/components/atomic/FullSpinnerLoading/FullSpinnerLoading.types';
import { FullSpinnerLoading } from 'src/shared/components/atomic';

const FullSpinnerLoadingContext = createContext<FullSpinnerLoadingContextProps>(
  {} as FullSpinnerLoadingContextProps
);

const FullSpinnerLoadingProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [show, setShow] = useState(false);

  const open = useCallback(() => {
    setShow(true);
  }, []);

  const hide = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <FullSpinnerLoadingContext.Provider value={{ open, hide }}>
      {children}
      <FullSpinnerLoading open={show} />
    </FullSpinnerLoadingContext.Provider>
  );
};

const useFullSpinnerLoadingContext = () => {
  const context = useContext(FullSpinnerLoadingContext);
  if (!context) {
    throw new Error(
      'useFullSpinnerLoadingContext deve ser utilizado dentro do FullSpinnerLoadingProvider'
    );
  }
  return context;
};

export { FullSpinnerLoadingProvider, useFullSpinnerLoadingContext };
