import camelcaseKeys from 'camelcase-keys';
import {
  authHttpClient,
  beneficiariesHttpClient,
  peopleHttpClient,
} from 'src/core/services';
import { type IDependent } from '../../../shared/interfaces/dependent';
import {
  type AppToken,
  type CreatePassword,
  type Login,
  type SendVerificationCode,
  type ValidateVerificationCode,
  type VerifyEmail,
} from '../interfaces';
import type LoginToken from '../interfaces/responses/loginToken';
import type User from '../interfaces/user';

const useHttp = () => {
  const getAppToken = async () => {
    return await authHttpClient
      .post(
        'connect/token',
        new URLSearchParams({
          grant_type: 'client_credentials',
          client_id: 'docwayapp',
          client_secret: 'secret',
          scope: 'api1',
        }).toString(),
        {
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
          },
        }
      )
      .then(resp => camelcaseKeys(resp.data) as AppToken);
  };

  const login = async (login: Login) => {
    return await authHttpClient
      .post<LoginToken>(
        'connect/token',
        new URLSearchParams({
          username: login.email,
          password: login.password,
          grant_type: 'password',
          client_id: 'ro.client',
          client_secret: 'secret',
          scope: 'api1',
        })
      )
      .then(resp => camelcaseKeys(resp.data) as AppToken);
  };

  const verifyEmail = async (email: string) => {
    return await peopleHttpClient
      .post<VerifyEmail>(`validate/${email}`)
      .then(res => res.data);
  };

  const getUserByEmail = async (email: string) => {
    return await peopleHttpClient
      .get<User>(`Email/${email}`)
      .then(res => res.data);
  };

  const sendVerificationCode = async (request: SendVerificationCode) => {
    return await peopleHttpClient
      .post('VerificationCode', request)
      .then(res => res.data);
  };

  const validateVerificationCode = async (
    request: ValidateVerificationCode
  ) => {
    return await peopleHttpClient
      .post('VerificationCode/Validate', request)
      .then(res => res.data);
  };

  const createPassword = async (request: CreatePassword) => {
    return await peopleHttpClient
      .post('CreatePassword', request)
      .then(res => res.data);
  };

  const updateHolder = async (request: IDependent) => {
    const body = { ...request, birthDate: request.dateOfBirth };
    return await beneficiariesHttpClient
      .put('Holder', body)
      .then(res => res.data);
  };

  const updateBeneficiary = async (request: IDependent) => {
    const requestId = request.personId ?? '';
    return await peopleHttpClient
      .put(`${requestId}`, request)
      .then(res => res.data);
  };

  return {
    getAppToken,
    login,
    verifyEmail,
    sendVerificationCode,
    validateVerificationCode,
    createPassword,
    updateHolder,
    getUserByEmail,
    updateBeneficiary,
  };
};

export default useHttp;
