import React, { useState } from 'react';

import BaseDependentLayout from 'src/shared/components/layout/BaseDependentLayout';
import { Grid, Typography } from 'src/shared/components/atomic';
import { useAuthContext } from 'src/modules/Auth';
import { useFullSpinnerLoadingContext } from 'src/shared/components/atomic/FullSpinnerLoading';
import { useModalContext } from 'src/shared/components/atomic/Modal';
import { useSnackbarContext } from 'src/shared/components/atomic/Snackbar';
import { useUpdateHolder } from 'src/modules/Auth/api';
import { useUpdateBasicUserInfo } from 'src/modules/Auth/hooks';

import * as sx from './SignaturesPage.styles';
import { SignaturesList } from './components';
import { DependentForm } from 'src/shared/components/dependentForm';
import { type ErrorResponse } from 'src/modules/Auth/interfaces';
import { useRemoveMask, useTransformDate } from 'src/core/helpers';
import { type IDependent } from 'src/shared/interfaces/dependent';

const SignaturesPage = () => {
  const { user } = useAuthContext();
  const { setModalContent } = useModalContext();
  const { openSnackbar } = useSnackbarContext();
  const { open, hide } = useFullSpinnerLoadingContext();
  const { mutate: updateHolder } = useUpdateHolder();
  const { updateBasicUserInfo } = useUpdateBasicUserInfo();

  const perfilOnClick = () => {
    setModalContent({
      title: 'Seu perfil',
      component: <DependentForm onSubmit={handleSubmit} holder={user} isEdit />,
    });
  };

  const handleSubmit = (dependent: IDependent) => {
    open();
    dependent.dateOfBirth = useTransformDate(dependent.dateOfBirth ?? '');
    dependent.phoneNumber = useRemoveMask(dependent.phoneNumber ?? '');
    dependent.documentNumber = useRemoveMask(dependent.documentNumber ?? '');
    dependent.id = user.id;
    updateHolder(dependent, {
      onSuccess: async () => {
        openSnackbar('Seu perfil foi atualizado com sucesso!');
        await updateBasicUserInfo(user.email);
        setModalContent(null);
      },
      onError: e => {
        const error = e as XMLHttpRequest;
        const response = JSON.parse(error.responseText) as ErrorResponse;

        if (response.message) {
          openSnackbar(response.message, {
            autoHideDuration: 3000,
            severity: 'error',
          });
          return;
        }

        openSnackbar(
          'Um erro aconteceu ao tentar atualizar o seu perfil. Tente novamente.',
          {
            autoHideDuration: 3000,
            severity: 'error',
          }
        );
      },
      onSettled: () => {
        hide();
      },
    });
  };

  return (
    <BaseDependentLayout>
      <BaseDependentLayout.Header
        showMenu
        buttonText={user.name ?? ''}
        perfilOnClick={() => {
          perfilOnClick();
        }}
      />
      <BaseDependentLayout.Body>
        <Grid sx={sx.container} container>
          <Grid sx={sx.paperHeader} direction="column" item container>
            <Typography variant="h1" sx={sx.title}>
              Assinaturas
            </Typography>
            <Typography>
              Aqui você pode gerenciar as suas assinaturas, incluindo, editando
              ou atualizando os seus dados e de dependentes.
            </Typography>
          </Grid>
          <Grid sx={sx.paperBody} direction="column" item container>
            <SignaturesList />

            <Grid item container>
              <Grid item sx={{ paddingTop: '24px' }}>
                <Typography sx={{ maxWidth: '851px' }}>
                  Agora você já pode realizar agendamentos para os serviços de
                  saúde contratados através da nossa
                  <a
                    style={sx.link}
                    href="https://oifibra.docway.com.br/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    página oficial
                  </a>
                  ,
                  <a
                    style={sx.link}
                    href="https://play.google.com/store/apps/dev?id=8161234029328702769&hl=pt_BR&gl=US"
                    target="_blank"
                    rel="noreferrer"
                  >
                    App do Beneficiário
                  </a>
                  ou ainda pela Central de Atendimento, ligando no 3003-6291.
                </Typography>
                <Typography sx={{ paddingTop: '10px' }}>
                  Bom atendimento 💚
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BaseDependentLayout.Body>
    </BaseDependentLayout>
  );
};

export default SignaturesPage;
