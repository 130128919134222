import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthLayout } from 'src/modules/Auth/layout';
import { Form, Grid, Typography } from 'src/shared/components/atomic';

import { useFullSpinnerLoadingContext } from 'src/shared/components/atomic/FullSpinnerLoading';
import { useSnackbarContext } from 'src/shared/components/atomic/Snackbar';

import { useAuthContext } from 'src/modules/Auth';

import {
  useSendVerificationCode,
  useValidateVerificationCode,
} from 'src/modules/Auth/api';

import * as sx from 'src/modules/Auth/pages/VerificationCode/VerificationCodePage.styles';
import VerificationCodePageForm, {
  VerificationCodePageFormSchema,
} from 'src/modules/Auth/pages/VerificationCode/components/VerificationCodePageForm';
import type VerificationCodeForm from 'src/modules/Auth/interfaces/verificationCodeForm';
import { type ErrorResponse } from '../../interfaces';

import { useUpdateBasicUserInfo } from 'src/modules/Auth/hooks';

const VerificationCodePage = () => {
  const [hasErrors, setHasErrors] = useState(false);
  const [isAllFilled, setIsAllFilled] = useState(false);

  const { user } = useAuthContext();
  const userEmail = user.email;

  const { isLoading, data: sendVerificationCodeResult } =
    useSendVerificationCode(userEmail);

  const { mutate: validateVerificationCode } = useValidateVerificationCode();

  const { updateBasicUserInfo } = useUpdateBasicUserInfo();

  const { open, hide } = useFullSpinnerLoadingContext();

  const { openSnackbar } = useSnackbarContext();

  useEffect(() => {
    if (isLoading) open();
    else hide();
  }, [hide, isLoading, open]);

  useEffect(() => {
    if (!sendVerificationCodeResult?.message) return;

    const message: string = sendVerificationCodeResult?.message;

    openSnackbar(`Problemas ao enviar código por e-mail: ${message}`, {
      severity: 'error',
      autoHideDuration: 3000,
    });
  }, [openSnackbar, sendVerificationCodeResult]);

  const handleLogin = async (form: VerificationCodeForm) => {
    open();

    const code = `${form.value1}${form.value2}${form.value3}${form.value4}`;

    validateVerificationCode(
      { code, email: userEmail },
      {
        onSuccess: async () => {
          openSnackbar('Verificado com sucesso!');
          await updateBasicUserInfo(userEmail);
          navigate('../create-password');
        },
        onError: e => {
          const error = e as XMLHttpRequest;
          const response = JSON.parse(error.responseText) as ErrorResponse;

          openSnackbar(response.message, {
            autoHideDuration: 3000,
            severity: 'error',
          });
        },
        onSettled: () => {
          hide();
        },
      }
    );
  };

  const navigate = useNavigate();

  const disbaleSendButton = hasErrors || !isAllFilled;

  return (
    <Form
      submitHandler={handleLogin}
      validationSchema={VerificationCodePageFormSchema}
      getErrors={errors => {
        setHasErrors(!!errors);
      }}
    >
      <AuthLayout
        showCancelButton={true}
        isSendButtonDisabled={disbaleSendButton}
        cancelOnClick={() => {
          navigate('../login', { replace: true });
        }}
      >
        <Grid item xs={12}>
          <Typography sx={sx.TitleText}>
            Por favor, verifique seu-email
          </Typography>
          <Typography sx={sx.MinPaddingTop}>
            Insira o código enviado para <strong>{userEmail}</strong>
          </Typography>
        </Grid>
        <VerificationCodePageForm isAllFilled={setIsAllFilled} />
      </AuthLayout>
    </Form>
  );
};

export default VerificationCodePage;
