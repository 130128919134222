import React from 'react';
import Snackbar, {
  useSnackbarContext,
} from 'src/shared/components/atomic/Snackbar';

const Global: React.FC = () => {
  const { isOpen, message, options } = useSnackbarContext();

  return (
    <Snackbar
      open={isOpen}
      severity={options.severity}
      autoHideDuration={options.autoHideDuration}
    >
      {message}
    </Snackbar>
  );
};

export default Global;
