export const baseLayout = {
  minHeight: '100vh',
  background: '#F1F1F1',
  display: 'flex',
  flexDirection: 'column',
  '& > *': {
    padding: '1.5rem',
  },
};

export const layoutHeader = {
  backgroundColor: '#FFF',
  fontSize: '24px',
  fontWeight: 700,
  maxHeight: '60px',
  backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/logo_oisaude.png'), url('${process.env.PUBLIC_URL}/imgs/logo_docway.png')`,
  backgroundPosition: 'calc(0% + 24px), calc(0% + 214px) 19px',
  backgroundRepeat: 'no-repeat, no-repeat',
  backgroundSize: '160px, 140px',
};

export const menuContainer = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '-15px',
};

export const layoutPageTitle = {
  backgroundColor: '#F8FAFC',
  fontSize: '24px',
  fontWeight: 700,
  maxHeight: '60px',
};

export const pageTitleText = {
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '100%',
  color: '#0F172A',
};

export const layoutBody = {
  overflow: 'auto',
  flex: 1,
};
