import { useCallback, useMemo } from 'react';

const useNumberToWords = () => {
  const words = useMemo(
    () => [
      'zero',
      'uma',
      'duas',
      'três',
      'quatro',
      'cinco',
      'seis',
      'sete',
      'oito',
      'nove',
      'dez',
      'onze',
      'doze',
      'treze',
      'quatorze',
      'quinze',
    ],
    []
  );

  const numberToWords = useCallback(
    (number: number, femaleArticle = true): string => {
      if (femaleArticle) return words[number];
      const numberToWord = words[number];
      if (number !== 1 && number !== 2) return numberToWord;
      return number === 1 ? 'um' : 'dois';
    },
    [words]
  );

  return { numberToWords };
};

export default useNumberToWords;
