import React from 'react';
import MuiMenuItem from '@mui/material/MenuItem';

import type MenuItemProps from './MenuItem.types';

const MenuItem = ({ children, ...rest }: MenuItemProps) => {
  return <MuiMenuItem {...rest}>{children}</MuiMenuItem>;
};

export default MenuItem;
