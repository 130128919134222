import { MinPaddingTop } from 'src/modules/Auth/pages/VerificationCode/VerificationCodePage.styles';

export const Container = {
  ...MinPaddingTop,
};

export const SquareField = {
  width: '20%',
  '& .MuiInputBase-root': {
    height: '70px',
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '38px',
  },
  '& .MuiInputBase-inputSizeSmall': {
    textAlign: 'center',
  },
};

export const CaptionText = {
  display: 'flex',
  flexWrap: 'wrap',
  ...MinPaddingTop,
};

export const LinkButton = {
  pl: '6px',
};
