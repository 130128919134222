import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, {
  tooltipClasses,
  type TooltipProps,
} from '@mui/material/Tooltip';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&:before': {
      boxShadow: theme.shadows[1],
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: '14px',
    color: '#0F172A',
    maxWidth: '340px',
    padding: 0,
  },
}));

export default StyledTooltip;
