import { useMutation } from 'react-query';
import { useHttp } from 'src/modules/Dependent/api';
import { type IDependent } from 'src/shared/interfaces/dependent';

export const useGetDependents = () => {
  const { getDependents } = useHttp();

  return useMutation(async (holderId: string) => await getDependents(holderId));
};

export const useSendDependent = () => {
  const { sendDependent } = useHttp();

  return useMutation(
    async (dependent: IDependent) => await sendDependent(dependent)
  );
};

export const useUpdateDependent = () => {
  const { updateDependent } = useHttp();

  return useMutation(
    async (args: { dependent: IDependent; dependentId: string }) =>
      await updateDependent(args.dependent, args.dependentId)
  );
};

export const useActivateDependent = () => {
  const { activateDependent } = useHttp();

  return useMutation(
    async (args: { dependentId: string; signatureId: string }) =>
      await activateDependent(args.dependentId, args.signatureId)
  );
};

export const useInactivateDependent = () => {
  const { inactivateDependent } = useHttp();

  return useMutation(
    async (args: { signatureId: number; dependentId: string }) =>
      await inactivateDependent(args.dependentId, args.signatureId)
  );
};
