// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { updateAuthToken } from 'src/core/services';
import { useLocalStorage } from 'src/core/storages';

import type AuthContextProps from 'src/modules/Auth/contexts/AuthContext.types';
import type User from 'src/modules/Auth/interfaces';
import { type AppToken } from 'src/modules/Auth/interfaces';

import { usePageProtection } from 'src/modules/Auth/contexts/hooks';

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

const defaultUser: User = {
  signatureId: 0,
  email: '',
  id: '',
  name: '',
  dateOfBirth: '',
  documentNumber: '',
  gender: '',
  isHolder: true,
  personId: '',
  phoneNumber: '',
  status: 'Ativo',
};

const AuthContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User>(defaultUser);
  const [userEmail, setUserEmail] = useState('');
  const [hasTempToken, setHasTempToken] = useState(false);

  const { setLocalStorageItem, getLocalStorageItem, clearLocalStorage } =
    useLocalStorage();

  const location = useLocation();

  const navigate = useNavigate();

  const { protectThisPage } = usePageProtection();

  const updateAppToken = useCallback((token: AppToken) => {
    updateAuthToken(token.accessToken);
    setHasTempToken(true);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      setUser(user);
      setLocalStorageItem('$AUTH-USER-INFO', user);
    },
    [setLocalStorageItem]
  );

  const updateLoginToken = useCallback(
    (token: AppToken) => {
      updateAuthToken(token.accessToken);

      setLocalStorageItem('$AUTH-TOKEN', token);
    },
    [setLocalStorageItem]
  );

  const updateUserEmail = useCallback(
    (email: string) => {
      setUserEmail(email);
      updateUser({ ...user, email });
    },
    [updateUser, user]
  );

  const logout = useCallback(
    (doNotRedirect?: boolean) => {
      clearLocalStorage();
      setUser(defaultUser);
      setUserEmail('');
      setHasTempToken(false);
      updateAuthToken('');

      if (doNotRedirect) return;
      navigate('/auth/login', { replace: true });
    },
    [clearLocalStorage, navigate]
  );

  const getStoredLoginToken = useCallback(
    (updateHeaders = true) => {
      const loginToken = getLocalStorageItem<AppToken>('$AUTH-TOKEN');

      if (loginToken && updateHeaders) updateAppToken(loginToken);

      return !!loginToken;
    },
    [getLocalStorageItem, updateAppToken]
  );

  const getStoredUserInfo = useCallback(() => {
    const userInfo = getLocalStorageItem<User>('$AUTH-USER-INFO');

    if (userInfo) setUser(userInfo);
  }, [getLocalStorageItem]);

  useEffect(() => {
    getStoredLoginToken();
    getStoredUserInfo();
  }, [getStoredLoginToken, getStoredUserInfo]);

  const shouldForceLogout = useCallback(() => {
    const hasLoginToken = getStoredLoginToken(false);
    return protectThisPage(location.pathname, hasTempToken, hasLoginToken);
  }, [getStoredLoginToken, hasTempToken, location.pathname, protectThisPage]);

  return (
    <AuthContext.Provider
      value={{
        updateAppToken,
        user,
        updateLoginToken,
        userEmail,
        updateUserEmail,
        updateUser,
        logout,
        shouldForceLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(
      'useAuthContext deve ser utilizado dentro do AuthContextProvider'
    );
  }
  return context;
};

export { AuthContextProvider, useAuthContext };
