const sx = {
  tableCell: {
    fontSize: '1rem',
    fontWeight: 'medium',
    ':is(th)': {
      fontWeight: 'bold',
    },
    height: '66px',
  },
};

export default sx;
