export const container = {
  gap: '32px',
  padding: '32px 24px',
  flexDirection: 'column',
  maxWidth: '835px',
  minWidth: '624px',
};

export const formContainer = {
  gap: '40px',
  flexDirection: 'column',
};

export const button = {
  textTransform: 'capitalize',
};

export const totalDependentsWrapper = {
  flexGrow: 1,
};

export const totalDependentsText = {
  color: '#F97316',
};

export const holderTitle = {
  fontSize: '20px',
  fontWeight: '700',
  color: '#0F172A',
};

export const editHeader = {
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'nowrap',
};

export const statusContainer = {
  display: 'flex',
  alignItems: 'center',
};

export const status = {
  maxHeight: '32px',
  padding: '8px',
  display: 'inline-block',
  color: '#578040',
  borderColor: '#578040',
  '& .MuiAlert-message': {
    padding: '0px',
    lineHeight: '1.11',
  },
  ml: '8px',
};
