import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import AuthRoutes, {
  AuthContextProvider,
  PageProtection,
} from 'src/modules/Auth';
import DependentRoutes from 'src/modules/Dependent/pages/dependent.routes';
import SignaturesRoutes from 'src/modules/Signatures';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <Routes>
          <Route path="" Component={PageProtection}>
            <Route path="auth/*" Component={AuthRoutes} />
            <Route path="dependents/*" Component={DependentRoutes} />
            <Route path="signatures/*" Component={SignaturesRoutes} />
          </Route>

          <Route
            index
            path="/"
            element={<Navigate to={'auth/login'} replace />}
          />
        </Routes>
      </AuthContextProvider>
    </BrowserRouter>
  );
};

export default AppRoutes;
