import { useMutation, useQuery } from 'react-query';
import useHttp from 'src/modules/Auth/api/http';
import { type IDependent } from '../../../shared/interfaces/dependent';
import {
  type Login,
  type AppToken,
  type CreatePassword,
  type ValidateVerificationCode,
} from '../interfaces';

export const useGetAppToken = () => {
  const { getAppToken } = useHttp();

  return useQuery<AppToken>(['getAppToken'], async () => await getAppToken());
};

export const useLogin = () => {
  const { login } = useHttp();

  return useMutation(async (request: Login) => await login(request));
};

export const useVerifyEmail = () => {
  const { verifyEmail } = useHttp();

  return useMutation(async (email: string) => await verifyEmail(email));
};

export const useSendVerificationCode = (email: string) => {
  const { sendVerificationCode } = useHttp();

  return useQuery(
    ['sendVerificationCode', email],
    async () => await sendVerificationCode({ email, resendCode: false })
  );
};

export const useGetUserByEmail = () => {
  const { getUserByEmail } = useHttp();

  return useMutation(async (email: string) => await getUserByEmail(email));
};

export const useReSendVerificationCode = () => {
  const { sendVerificationCode } = useHttp();

  return useMutation(
    async (email: string) =>
      await sendVerificationCode({ email, resendCode: true })
  );
};

export const useValidateVerificationCode = () => {
  const { validateVerificationCode } = useHttp();

  return useMutation(
    async (request: ValidateVerificationCode) =>
      await validateVerificationCode(request)
  );
};
export const useCreatePassword = () => {
  const { createPassword } = useHttp();

  return useMutation(
    async (request: CreatePassword) => await createPassword(request)
  );
};

export const useUpdateHolder = () => {
  const { updateHolder } = useHttp();

  return useMutation(
    async (request: IDependent) => await updateHolder(request)
  );
};

export const useUpdateBeneficiary = () => {
  const { updateBeneficiary } = useHttp();

  return useMutation(
    async (request: IDependent) => await updateBeneficiary(request)
  );
};
