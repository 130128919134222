import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import type FullSpinnerLoadingProps from 'src/shared/components/atomic/FullSpinnerLoading/FullSpinnerLoading.types';

const FullSpinnerLoading = ({ open, ...rest }: FullSpinnerLoadingProps) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 10000000000 }}
      open={open}
      {...rest}
    >
      <CircularProgress />
    </Backdrop>
  );
};

export default FullSpinnerLoading;
