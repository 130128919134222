import React, { type PropsWithChildren } from 'react';

import { Paper } from 'src/shared/components/atomic';

import * as s from './BaseAuthLayout.styles';

const BaseAuthLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <s.BackgroundContainer>
      <Paper elevation={3} sx={{ p: '4rem 3rem 3rem;' }}>
        {children}
      </Paper>
    </s.BackgroundContainer>
  );
};

export default BaseAuthLayout;
