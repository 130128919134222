import React from 'react';
import ReactDOM from 'react-dom/client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import reportWebVitals from './reportWebVitals';

import AppRoutes from './App.routes';
import { DefaultTheme } from 'src/shared/theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ModalContextProvider } from 'src/shared/components/atomic/Modal/Modal.context';
import { SnackbarContextProvider } from 'src/shared/components/atomic/Snackbar/Snackbar.context';
import Global from './Global';
import { FullSpinnerLoadingProvider } from './shared/components/atomic/FullSpinnerLoading';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 0,
      staleTime: 1000 * 60 * 0,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={DefaultTheme}>
      <QueryClientProvider client={queryClient}>
        <FullSpinnerLoadingProvider>
          <SnackbarContextProvider>
            <ModalContextProvider>
              <CssBaseline />
              <Global />
              <AppRoutes />
            </ModalContextProvider>
          </SnackbarContextProvider>
        </FullSpinnerLoadingProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
