import { MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Form,
  Grid,
  Select,
  TextField,
} from 'src/shared/components/atomic';
import * as sx from 'src/shared/components/dependentForm/dependentForm.style';
import { type DependentFormProps } from 'src/shared/components/dependentForm/dependentForm.types';
import * as yup from 'yup';
import { useModalContext } from 'src/shared/components/atomic/Modal';

const DependentForm: React.FC<DependentFormProps> = (
  props: DependentFormProps
) => {
  const [userProps, setUserProps] = useState(props.holder);
  const { setModalContent } = useModalContext();

  useEffect(() => {
    if (!props.dependentData) setUserProps(props.holder);
    else setUserProps(props.dependentData);
  }, [props.holder, props.dependentData]);

  const FormValidationSchema = yup.object().shape({
    name: yup.string().required('Nome é obrigatório'),
    documentNumber: yup.string().required('CPF é obrigatório'),
    email: yup
      .string()
      .email('O e-mail precisa ser válido')
      .required('E-mail é obrigatório'),
    phoneNumber: yup.string().required('Telefone é obrigatório'),
    dateOfBirth: yup.string().required('Data de Nascimento é obrigatório'),
    gender: yup.string().required('Sexo é obrigatório'),
  });

  const convertDate = (date: string) => {
    if (date) {
      const newDate = date.slice(0, 10);
      const dateSlices = newDate.split('-');
      return `${dateSlices[2]}/${dateSlices[1]}/${dateSlices[0]}}`;
    }
    return date;
  };

  if (props.dependentData && !userProps?.documentNumber) {
    return <></>;
  }
  return (
    <Grid container sx={sx.container}>
      {props.isDependent && !props.dependentData && (
        <Grid container item sx={{ gap: '24px', flexDirection: 'column' }}>
          <Typography sx={{ maxWidth: '650px' }}>
            Para cadastrar seu dependente, por favor preencha os dados abaixo.
          </Typography>

          <Grid container item>
            <Grid item sx={sx.totalDependentsWrapper}>
              <Typography sx={sx.totalDependentsText}>
                Dependentes cadastrados: {props.beneficiariesLength}/
                {props.totalBeneficiariesAvailable}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!props.isDependent && !props.dependentData && !props.isEdit && (
        <Grid container item sx={{ gap: '24px', flexDirection: 'column' }}>
          <Grid item>
            <Typography sx={sx.holderTitle}>
              Bem vindo, {userProps?.name}!
            </Typography>
          </Grid>
          <Grid>
            <Typography>
              Quase lá, precisamos que você cadastre seus dados.
            </Typography>
          </Grid>
        </Grid>
      )}
      {props.isEdit && (
        <Grid container item sx={sx.editHeader}>
          <Typography>
            Permissão: {props.holder ? 'Titular' : 'Dependente'}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Form
          submitHandler={props.onSubmit}
          validationSchema={FormValidationSchema}
        >
          <Grid container item sx={sx.formContainer}>
            <TextField
              name="name"
              variant="standard"
              label="Nome Completo"
              value={userProps?.name}
              disabled={props.isEdit}
            />
            <TextField
              name="documentNumber"
              variant="standard"
              label="CPF"
              mask="###.###.###-##"
              value={userProps?.documentNumber}
              disabled={props.isEdit}
            />
            <TextField
              name="email"
              variant="standard"
              label="E-mail"
              value={userProps?.email}
              type="email"
              disabled={!!props.holder}
            />
            <TextField
              name="dateOfBirth"
              variant="standard"
              label="Data de nascimento"
              mask="##/##/####"
              value={convertDate(userProps?.dateOfBirth ?? '')}
              disabled={props.isEdit}
            />
            <TextField
              name="phoneNumber"
              variant="standard"
              label="Número de telefone"
              mask="(##)#####-####"
              value={userProps?.phoneNumber}
            />
            <Select
              name="gender"
              variant="standard"
              label="Sexo"
              value={userProps?.gender}
              disabled={props.isEdit}
            >
              <MenuItem value="Male">Masculino</MenuItem>
              <MenuItem value="Female">Feminino</MenuItem>
            </Select>
            {props.isEdit ? (
              <>
                <TextField
                  name="personId"
                  value={userProps?.personId}
                  type="hidden"
                  sx={{ display: 'none' }}
                />
                <TextField
                  name="id"
                  value={userProps?.id}
                  type="hidden"
                  sx={{ display: 'none' }}
                />
              </>
            ) : (
              <></>
            )}
          </Grid>
          <Grid
            container
            sx={{ justifyContent: 'center', pt: '30px', gap: '16px' }}
          >
            {!props.hideCancelButton && (
              <Grid item sx={{ flexGrow: 1 }}>
                <Button
                  sx={sx.button}
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    setModalContent(null);
                  }}
                >
                  Cancelar
                </Button>
              </Grid>
            )}
            <Grid item sx={{ flexGrow: 1 }}>
              <Button
                type="submit"
                variant="contained"
                sx={sx.button}
                fullWidth
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Grid>
    </Grid>
  );
};

export default DependentForm;
